import { useFormik } from "formik";
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingIndicator, FormActions, FormFields } from '../../../components'
import { fieldBuilder } from '../../../utils'
import { validationSchema } from './validationSchema'
import { useCRUD, useNotifications } from '../../../hooks';
import { categoriesService } from '../../../services';

const CategoriesForm = ({ mode }: { mode: string }) => {
	const title = `${mode === 'add' ? 'Agregar' : 'Editar'} categoría`
	const history = useHistory()
	const { state: locationState }: any = useLocation()
	const { showNotification } = useNotifications()

	const { handleAdd, handleEdit, loading } = useCRUD(
		null,
		categoriesService.add,
		categoriesService.edit,
		categoriesService.remove,
		showNotification,
		categoriesService.getMessages
	)

	const formik = useFormik({
		initialValues: {
			name: locationState?.name || ''
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			if(mode === 'add')
				await handleAdd(values)
			else
				await handleEdit({
					id: locationState.id,
					...values
				})
			history.push('/categorias')
		},
	})

	const fields = [
		{ id: 'name', label: 'Nombre' },
	].map(x => fieldBuilder(x, formik)).filter(x => !x.hidden)

	return <>
		<LoadingIndicator loading={loading} />

		<h1>{title}</h1>

		<form onSubmit={formik.handleSubmit}>
			<FormFields fields={fields} />
			<FormActions baseUrl="/categorias" />
		</form>
	</>
}

export default CategoriesForm
