import { useState } from 'react'

const themeObject ={
	palette: {
		type: 'dark',
		primary: {
			main: '#d2f81b'
		},
		secondary: {
			main: '#445d7b'
		}
	}
}

const useThemeMode = () => {
	const [theme, setTheme] = useState(themeObject)
	const { palette: { type } } = theme

	const toggleThemeMode = () => {
		const updatedTheme: any = {
			...theme,
			palette: {
				...theme.palette,
				type: type === 'light' ? 'dark' : 'light',
				primary: {
					main: '#d2f81b'
				},
				secondary: {
					main: '#445d7b'
				}
			}
		}
		setTheme(updatedTheme)
	}

	return [theme, toggleThemeMode]
}

export default useThemeMode
