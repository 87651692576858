import baseService from './baseService'

const reparationsService = {
	get: async() => baseService.httpGet('Repair'),
	changeStatus: async(nroRepair: number, state: boolean) => baseService.httpPut(
		'Repair/ChangeState',
		{},
		{ nroRepair, state }
	),

	getMessages: (type: string, response: any) => {
		switch(type){
			case 'GET': return {
				error: 'Se produjo un error al intentar obtener las reparaciones'
			}
			default: return {
				error: 'Se produjo un error desconocido'
			}
		}
	}
}

export default reparationsService
