import { decode_utf8 } from "../hooks"

const fieldBuilder = (x: any, formik: any) => ({
	...x,
	name: x.id,
	value: decode_utf8(formik.values[x.id]),
	onChange: formik.handleChange,
	error: formik.touched[x.id] && Boolean(formik.errors[x.id]),
	helperText: formik.touched[x.id] && formik.errors[x.id]
})

export default fieldBuilder
