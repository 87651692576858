
import { GridToolbar, DataGrid } from '@material-ui/data-grid';

type Props = {
	rows: any,
	columns: any
	pageSize?: number
	height?: number
	getRowId?: (x: any) => any
}

const DefaultGrid = ({ rows, columns, pageSize = 10, height = 750, getRowId = (x => x.id) }: Props) => {
	return(
		<div style={{ height: height, width: '100%' }}>
			<DataGrid
				rows={rows}
				columns={columns}
				pageSize={pageSize}
				checkboxSelection={false}
				components={{ Toolbar: GridToolbar }}
				disableColumnMenu
				getRowId={getRowId}
			/>
		</div>
	)
}

export default DefaultGrid
