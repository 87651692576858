import { Paper, Tabs, Tab } from '@material-ui/core';
import {
  GridHeader,
} from '../../../components';
import {getDataHead, GetDataBody, headerExport, getDataExport, getDataSubFooter} from './columnsGetter';
import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../App';
import { comissionService } from '../../../services';
import { CustomGridTable } from "../../../components/grid/CustomGrid";
import { changeViewColumn } from '../../../components/grid/CustomGrid/Funtions';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import 'moment/locale/es';

const CommissionGrid = () => {
	const history = useHistory();

	const querystring = window.location.search
	const params = new URLSearchParams(querystring)

	const [tabValue, setTabValue] = useState(parseInt(params.get('tab') || "0"));
	const [typeComission, setTypeComission] = useState("seller");
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');
	const [rows, setRows] = useState([]);
	const { userState } = useContext(AuthContext);

	const [dataHeadColumns, setDataHeadColumns] = useState(getDataHead);
  
	const handleChangeViewColumn = (key: string) => {
	  changeViewColumn(key, "dataHeadComisiones", dataHeadColumns, setDataHeadColumns)
	}
	
	const [page, setPage] = useState(parseInt(params.get('page') || "0"));
	const changePage = (newPage: number) => {
	  history.push(`?page=${newPage}`)
	  setPage(newPage);
	}
	
	useEffect(() => {
		comissionService.get(typeComission,{fromDate:moment(fromDate).format("YYYY-MM-DD"),toDate:moment(toDate).format("YYYY-MM-DD")}).then((comissions)=>setRows(comissions));
		if (typeComission!=="seller") {
			setDataHeadColumns(dataHeadColumns.filter((cell: any)=>cell.key!=="collaboratorName" && cell.key!=="collaboratorColor"));
		}
		else {
			setDataHeadColumns(getDataHead());
		}
	}, [typeComission,fromDate,toDate])

	let arrayTypeComission: string[] = [
		"seller","delivery","collaborator"
	]

  return (
	<>
	  <GridHeader
		title="Comisiones"
	  >
		<>
		<Paper square>
		  <Tabs
			value={tabValue}
			indicatorColor="primary"
			textColor="primary"
			onChange={(e, val) => {
			  val !== 0 ? history.push(`?page=${params.get('page')||0}&tab=${val}`) : history.push(`?page=${params.get('page')||0}`);
			  setTabValue(val);
			  setTypeComission(arrayTypeComission[val]);
			}}
			aria-label="disabled tabs example"
		  >
			{<Tab label="Vendedor" />}
			{(userState.type === 'admin') && <Tab label="Repartidor" />}
			{(userState.type === 'admin') && <Tab label="Collaborator" />}
		  </Tabs>
		</Paper>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
			disableToolbar
			variant="inline"
			format="dd/MM/yyyy"
			margin="normal"
			id="fromDate"
			value={moment(fromDate).isValid() ? moment(fromDate).format("YYYY-MM-DD 00:00:00") : null}
			onChange={(e)=>setFromDate(moment(e).isValid() ? moment(e).format("YYYY-MM-DD") : "")}
			KeyboardButtonProps={{
				'aria-label': 'change date',
			}}
			/>
		</MuiPickersUtilsProvider>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
			disableToolbar
			variant="inline"
			format="dd/MM/yyyy"
			margin="normal"
			id="toDate"
			value={moment(toDate).isValid() ? moment(toDate).format("YYYY-MM-DD 23:59:59") : null}
			onChange={(e)=>setToDate(moment(e).isValid() ? moment(e).format("YYYY-MM-DD") : "")}
			KeyboardButtonProps={{
				'aria-label': 'change date',
			}}
			/>
		</MuiPickersUtilsProvider>
		</>
	  </GridHeader>

	  <CustomGridTable
		  cellsHead={dataHeadColumns} 
		  rowsBody={GetDataBody({rows, type: typeComission})} 
		  pageSize={10} 
		  pageParent={page}
		  changePage={changePage}
		  tableProps={{stickyHeader: true, size:"small"}}
		  rowsPerPageOptions={[5, 10, 25, 50]}
		  toolbarProps={{
			exportProps: {
			  data:getDataExport(rows), headers:headerExport, filename:"comisiones.csv"
			},
			filterProps: {
			  columns: dataHeadColumns,
			  handleChangeViewColumn
			}
		  }}
		  orderDefault={"asc"}
		  orderByDefault={dataHeadColumns[0].key}
		  rowsSubFooter={getDataSubFooter(rows)}
		/>
	</>
  );
};

export default CommissionGrid;
