import ReparationAction from './ReparationAction'
import { IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { zones, deliveryStatus } from '../../../constants';
import { GridCellParams } from '@material-ui/data-grid';
import { RowBody } from '../../../components/grid/CustomGrid/types';

type Params = {
	onReparation: (x: GridCellParams) => void,
	userType: string
	handleViewClick: (x: GridCellParams) => void
	rows: any
}

export function GetDataBody({ rows,
	onReparation,
	handleViewClick,
	userType
}: Params) {
	let dataBody: RowBody[] = [];

	const getAccions = (row: any) => {

		return (
			<>
				{ (userType === 'admin' || userType === 'collaborator')
					&& <ReparationAction
						params={row}
						onReparation={onReparation}
					/>
				}
				<IconButton
					aria-label="view"
					onClick={() => {handleViewClick(row)}}
				>
					<VisibilityIcon />
				</IconButton>
			</>
		);
	}


	function GetRow(row: any): any[] {
		return [
			{
				cells: [
					{ key: 'nrorepair', field: row.nrorepair, tableCellProps: { component: "th", scope: "row", className: "stickySaeta" } },
					{ key: 'seller', field: `${row.seller.firstName} ${row.seller.lastName}` },
					{ key: 'saleDate', field: `${row.repairdate || 'Desconocida'}` },
					{ key: 'client', field: `${row.client.firstname} ${row.client.lastname}` },
					{ key: 'location', field: `${row.client.location || 'Desconocida'}` },
					{ key: 'zone', field: zones[row.client.zone] || 'Desconocido' },
					{ key: 'deliveryStatus', field: deliveryStatus[row.deliveryState] || 'Desconocido' },
					{ key: 'actions', field: getAccions(row) }
				],
				tableRowProps: {
					hover: true
				}
			}
		]
	}

	rows.map((row: any) => {
		row.nrorepair && GetRow(row).map((data: any) => dataBody.push(data));
	});
	return dataBody;
}

export const getDataHead = () => {
	let dataHeadTag = sessionStorage.getItem('dataHeadReparaciones');
	if (dataHeadTag) {
		return JSON.parse(dataHeadTag)
	}
	return dataHead();
}

export const dataHead = () => [
	{ key: 'nrorepair', field: "Número", tableCellProps: { align: "center", width: 40, className: "separador stickySaeta" } },
	{ key: 'seller', field: "Vendedor", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'saleDate', field: "Fecha de venta", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'client', field: "Cliente", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'location', field: "Localidad", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'zone', field: "Zona", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'deliveryStatus', field: "Estado de entrega", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'actions', field: "Acciones", tableCellProps: { align: "center" } }
];

export const headerExport: any[] = [
	{ key: 'nrorepair', label: 'Número' },
	{ key: 'seller', label: 'Vendedor' },
	{ key: 'saleDate', label: 'Fecha de venta' },
	{ key: 'client', label: 'Cliente' },
	{ key: 'location', label: 'Localidad' },
	{ key: 'zone', label: 'Zona' },
	{ key: 'deliveryStatus', label: 'Estado de entrega' },
];

export function getDataExport(rows: any[]) {
	let dataBody: any[] = [];

	rows.map((row: any) => (
		dataBody.push(
		{
			nrorepair: row.nrorepair,
			seller: `${row.seller.firstName} ${row.seller.lastName}`,
			saleDate: `${row.repairdate || 'Desconocida'}`,
			client: `${row.client.firstname} ${row.client.lastname}`,
			location: `${row.client.location || 'Desconocida'}`,
			zone: zones[row.client.zone] || 'Desconocido',
			deliveryStatus: deliveryStatus[row.deliveryState] || 'Desconocido'
		}))
	);
	return dataBody;
}