import { useCRUD, useNotifications } from '../../../hooks';
import { AuthContext } from '../../../App';
import reparationsService from '../../../services/reparationsService';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, Divider, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { buyerTypeOptions, deliveryStatusOptions, zoneOptions } from '../../sells/form/options';
import { GridHeader, LoadingIndicator } from '../../../components';
import {getDataHead, GetDataBody, headerExport, getDataExport} from './columnsGetter';
import { CustomGridTable } from "../../../components/grid/CustomGrid";
import { changeViewColumn } from '../../../components/grid/CustomGrid/Funtions';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

const ReparationsGrid = () => {
	const { showNotification } = useNotifications()
	const { userState } = useContext(AuthContext)
	const [modalVisible, setModalVisible] = useState(false)
	const [viewData, setViewData] = useState<any>({})

	const { loading, state: rows, setLoading, setState } = useCRUD(
		reparationsService.get,
		reparationsService.get,
		reparationsService.get,
		reparationsService.get,
		showNotification,
		reparationsService.getMessages
	)

	const onReparation = async (params: any) => {
		try{
			setLoading(true)
			await reparationsService.changeStatus(params.nrorepair, true)
			const newRows = rows.filter(x => x.nrorepair !== params.nrorepair)
			setState(newRows)
			setLoading(false)
			showNotification('success', 'Producto devuelto al stock correctamente')
		} catch(e) {
			showNotification(
				'error',
				'Se produjo un error al devolver el producto al stock'
			)
		}
	}

	const handleViewClick = (params: any) => {
		setModalVisible(true)
		setViewData(params)
	}

	const history = useHistory();
  
	const querystring = window.location.search
	const params = new URLSearchParams(querystring)

	const [dataHeadColumns, setDataHeadColumns] = useState(getDataHead());

	const handleChangeViewColumn = (key: string) => {
		changeViewColumn(key, "dataHeadReparaciones", dataHeadColumns, setDataHeadColumns)
	}
	
	const [page, setPage] = useState(parseInt(params.get('page') || "0"));
	const changePage = (newPage: number) => {
		history.push(`?page=${newPage}`)
		setPage(newPage);
	}

	return <>
		<Dialog
			open={modalVisible}
			onClose={() => { setModalVisible(false) }}
			style={{ padding: 32 }}
			fullScreen
		>
			<DialogContent style={{ paddingTop: 32, paddingBottom: 32, paddingLeft: 84, paddingRight: 84 }}>
				<Grid container spacing={2} direction="row">

				<Grid item xs={12} md={4}>
					<List>
						<ListItem button>
							<ListItemText>Información:</ListItemText>
						</ListItem>
							<Divider></Divider>
						<ListItem button>
							<ListItemText>Nro. de Reparación: {viewData.nrorepair}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText>Colaborador: {viewData?.collaborator?.firstName} {viewData?.collaborator?.lastName}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText>Color del colaborador: {viewData?.collaborator?.color}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText>Estado de entrega {deliveryStatusOptions.find(x => x.value === viewData.client?.deliveryState)?.label || 'Desconocido'}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText>Repartidor: {viewData.deliveryMan?.firstName} {viewData.deliveryMan?.lastName}</ListItemText></ListItem>
						<ListItem button>
							<ListItemText>Devuelve producto: {viewData.returnProduct || 'Desconocido'}</ListItemText></ListItem>
						<ListItem button>
							<ListItemText>Cantidad: {viewData?.product?.length}</ListItemText></ListItem>
						<ListItem button>
							<ListItemText>Fecha de venta: {viewData.saleDate || 'Desconocida'}</ListItemText></ListItem>
						<ListItem button>
							<ListItemText>Vendedor: {viewData?.seller?.firstName} {viewData?.seller?.lastName}</ListItemText>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} md={4}>
					<List>
						<ListItem button>
							<ListItemText>Producto:</ListItemText>
						</ListItem>
							<Divider></Divider>
						<ListItem button>
							<ListItemText> Nombre: {viewData?.product?.[0]?.name}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Descipción: {viewData?.product?.[0]?.description}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Categoría: {viewData?.product?.[0]?.idCategory}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Sub-categoría: {viewData?.product?.[0]?.idSubCategory}</ListItemText>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} md={4}>
					<List>
						<ListItem button>
							<ListItemText>Comprador:</ListItemText>
						</ListItem>
							<Divider></Divider>
						<ListItem button>
							<ListItemText> Nombre: {viewData.client?.firstname}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Apellido: {viewData.client?.lastname}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> DNI: {viewData.client?.dni}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Dirección: {viewData.client?.address}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Entre calles: {viewData.client?.beetwenstreets}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Zona: {zoneOptions.find(x => x.value === viewData.client?.zone)?.label || 'Desconocido'}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Comentarios: {viewData.client?.comments}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Email: {viewData.client?.email}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Teléfono: {viewData.client?.phone}</ListItemText>
						</ListItem>
						<ListItem button>
							<ListItemText> Tipo comprador: {buyerTypeOptions.find(x => x.value === viewData.client?.typeclient)?.label || 'Desconocido'}</ListItemText>
						</ListItem>
					</List>
				</Grid>

				</Grid>
			</DialogContent>
		</Dialog>

		<LoadingIndicator loading={loading} />
		<GridHeader
			addUrl=""
			title="Reparaciones"
		/>
		<CustomGridTable
		  cellsHead={dataHeadColumns} 
		  rowsBody={GetDataBody({rows,
			onReparation,
			handleViewClick,
			userType: userState.type,
		  })} 
		  pageSize={10} 
		  pageParent={page}
		  changePage={changePage}
		  tableProps={{stickyHeader: true, size:"small"}}
		  rowsPerPageOptions={[5, 10, 25]}
		  toolbarProps={{
			exportProps: {
			data:getDataExport(rows), headers:headerExport, filename:"usuarios.csv", name:"usuarios"
			},
			filterProps: {
			columns: dataHeadColumns,
			handleChangeViewColumn
			}
		  }}
		  orderDefault={"asc"}
		  orderByDefault={dataHeadColumns[0].key}
		  idStoreFilter="filtrosReparaciones"
		/>
	</>
}

export default ReparationsGrid
