import baseService from './baseService'

const authService = {
	login: async(email: string, password: string) => baseService.httpLogin(
		'Login',
		{ email, password,plataforma:"WEB" }
	),
}

export default authService
