import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingIndicator, FormActions } from '../../../components';
import FileUpload from '../../../components/FileUpload'; // Asegúrate de importar FileUpload aquí
import { validationSchema } from '../form/validationSchema';
import { useCRUD, useNotifications } from '../../../hooks';
import { categoriesService, productsService } from '../../../services';
import { parseCSVFile } from '../../../utils/csvParser';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const ProductsUpdate = ({ mode }: { mode: string }) => {
  const title = 'Actualización masiva';
  const history = useHistory();
  const { state: locationState }: any = useLocation();
  const { showNotification } = useNotifications();

  const [fileData, setFileData] = useState<any[]>([]);
  const [previewData, setPreviewData] = useState<any[]>([]);

  const {
    handleAdd,
    handleEdit,
    loading,
  } = useCRUD(
    null,
    productsService.add,
    productsService.edit,
    productsService.remove,
    showNotification,
    productsService.getMessages
  );

  const { loading: loadingCategories, state: categoriesAndSubcategories } =
    useCRUD(
      categoriesService.getCategoriesAndSubCategories,
      async () => {},
      async () => {},
      async () => {},
      showNotification,
      categoriesService.getMessages
    );

  const formik = useFormik({
    initialValues: {
      ID: '',
      NOMBRE: '',
      DESCRIPCIÓN: '',
      CATEGORÍA: '',
      SUB_CATEGORÍA: '',
      PRECIO_MIN: '',
      COMISIÓN_DE_COLABORADOR_VENT_MIN: '',
      COMISIÓN_DE_VENDEDOR_VENT_MIN: '',
      PRECIO_MAY: '',
      COMISIÓN_DE_COLABORADOR_VENT_MAY: '',
      COMISIÓN_DE_VENDEDOR_VENT_MAY: '',
      PRECIO_MAY_2: '',
      COMISIÓN_DE_COLABORADOR_VENT_MAY_2: '',
      COMISIÓN_DE_VENDEDOR_VENT_MAY_2: '',
      STOCK: '',
      PRECIO_REDES: '',
      COMISIÓN_DE_COLABORADOR_REDES: '',
      COMISIÓN_DE_VENDEDOR_REDES: '',
      STOCK_REDES: '',
      COSTO_UNIT_ENVIO: '',
      COSTO: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('Submitting form with previewData:', previewData);
      try {
        for (const product of previewData) {
          const productDTO = mapCSVToDTO(product);
          console.log('Updating product:', productDTO);
          await handleEdit(productDTO);
        }
        showNotification('success','Cambios aplicados correctamente');
        history.goBack();
      } catch (error) {
        console.error('Error al aplicar los cambios:', error);
        showNotification('error', 'Error al aplicar los cambios');
      }
    },
  });

  const handleFileUpload = async (file: File) => {
    try {
      const data = await parseCSVFile(file);
      console.log('Datos cargados:', data);
      setFileData(data);
      setPreviewData(data);
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    }
  };

  const mapCSVToDTO = (product) => {
    return {
      Id: parseInt(product.ID, 10),
      Name: product.NOMBRE,
      Description: product.DESCRIPCIÓN,
      idCategory: parseInt(product.CATEGORÍA, 10),
      idSubCategory: parseInt(product.SUB_CATEGORÍA, 10),
      RetailPrice: parseFloat(product.PRECIO_MIN),
      CollaboratorCommissionRetail: parseFloat(product.COMISIÓN_DE_COLABORADOR_VENT_MIN),
      SellerCommissionRetail: parseFloat(product.COMISIÓN_DE_VENDEDOR_VENT_MIN),
      FullPrice: parseFloat(product.PRECIO_MAY),
      CollaboratorCommissionFull: parseFloat(product.COMISIÓN_DE_COLABORADOR_VENT_MAY),
      SellerCommissionFull: parseFloat(product.COMISIÓN_DE_VENDEDOR_VENT_MAY),
      FullPrice2: parseFloat(product.PRECIO_MAY_2),
      CollaboratorCommissionFull2: parseFloat(product.COMISIÓN_DE_COLABORADOR_VENT_MAY_2),
      SellerCommissionFull2: parseFloat(product.COMISIÓN_DE_VENDEDOR_VENT_MAY_2),
      FullPrice3: parseFloat(product.PRECIO_MAY_3), // Añadido para mayorista 3
      CollaboratorCommissionFull3: parseFloat(product.COMISIÓN_DE_COLABORADOR_VENT_MAY_3), // Añadido para mayorista 3
      SellerCommissionFull3: parseFloat(product.COMISIÓN_DE_VENDEDOR_VENT_MAY_3), // Añadido para mayorista 3
      stock: parseInt(product.STOCK, 10),
      gridPrice: parseFloat(product.PRECIO_REDES),
      CollaboratorCommissionGrid: parseFloat(product.COMISIÓN_DE_COLABORADOR_REDES),
      SellerCommissionGrid: parseFloat(product.COMISIÓN_DE_VENDEDOR_REDES),
      gridStock: parseInt(product.STOCK_REDES, 10),
      Cost: parseFloat(product.COSTO),
      ShippingUnitCost: parseFloat(product.COSTO_UNIT_ENVIO),
    };
  };

  return (
    <>
      <LoadingIndicator loading={loading || loadingCategories} />

      <h1>{title}</h1>

      <form onSubmit={formik.handleSubmit}>
        <FileUpload onFileUpload={handleFileUpload} />

        {previewData.length > 0 && (
          <>
            <h2>Vista previa de los cambios</h2>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(previewData[0]).map((key) => (
                    <TableCell key={key}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {previewData.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((value, i) => (
                      <TableCell key={i}>{String(value)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        <FormActions baseUrl="/productos" />
        <Button type="submit" color="primary" variant="contained" disabled={loading || loadingCategories}>
          Aplicar cambios
        </Button>
      </form>
    </>
  );
};

export default ProductsUpdate;
