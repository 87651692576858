import { allProfileOptions } from '../form/options';
import Switch from '@material-ui/core/Switch';
import { GridCellParams } from '@material-ui/data-grid'
import { GridActions } from '../../../components'
import { RowBody } from '../../../components/grid/CustomGrid/types';

type Params = {
  onEdit: (x: GridCellParams) => void;
  onDelete: (x: GridCellParams) => void;
  onActive: (x: GridCellParams) => void;
  userType: string;
	rows: any
}


export function GetDataBody({ rows,
	onEdit,
	onDelete,
	onActive,
	userType
}: Params) {
	let dataBody: RowBody[] = [];

	const getAccions = (row: any) => {

		return (
			<>
				<GridActions
					params={{ row }}
					handleEdit={onEdit}
					handleDelete={onDelete}
				/>
			</>
		);
	}


	function GetRow(row: any): any[] {
		return [
			{
				cells: [
					{ key: 'firstName', field: row.firstName, tableCellProps: { component: "th", scope: "row", className: "stickySaeta" } },
					{ key: 'lastName', field: row.lastName },
					{ key: 'idProfile', field: allProfileOptions.find((x) => x.value === row.idProfile)?.label || 'Desconocido' },
					{ key: 'email', field: row.email },
					{ key: 'phone', field: row.phone },
					...(userType === 'admin'
          ? [
              { key: 'status', field: <Switch
                checked={row.isActive}
                onClick={() => onActive(row)}
                name={`status${row.id}`}
                color="primary"
              />, filterDataCustom: {type:"switch", data: row.isActive ? "si" : "no", field: "name"},  },
              { key: 'actions', field: getAccions(row) },
            ]
          : [])
				],
				tableRowProps: {
					hover: true
				}
			}
		]
	}

	rows.map((row: any) => {
		row.id && GetRow(row).map((data: any) => dataBody.push(data));
	});
	return dataBody;
}

export const getDataHead = () => {
	let dataHeadTag = sessionStorage.getItem('dataHeadUsuarios');
	if (dataHeadTag) {
		return JSON.parse(dataHeadTag)
	}
	return dataHead();
}

export const dataHead = () => [
	{ key: 'firstName', field: "Nombre", tableCellProps: { align: "center", width: 40, className: "separador stickySaeta" } },
	{ key: 'lastName', field: "Apellido", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'idProfile', field: "Perfil", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'email', field: "Email", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'phone', field: "Teléfono", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'status', field: "Estado", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'actions', field: "Acciones", tableCellProps: { align: "center" } }
];

export const headerExport: any[] = [
	{ key: 'firstName', label: 'Nombre' },
	{ key: 'lastName', label: 'Apellido' },
	{ key: 'idProfile', label: 'Perfil' },
	{ key: 'email', label: 'Email' },
	{ key: 'phone', label: 'Teléfono' },
];

export function getDataExport(rows: any[]) {
	let dataBody: any[] = [];

	rows.map((row: any) => (
		dataBody.push(
		{
			firstName: row.firstName,
			lastName: row.lastName,
			idProfile: allProfileOptions.find((x) => x.value === row.idProfile)?.label || 'Desconocido',
			email: row.email,
			phone: row.phone
		}))
	);
	return dataBody;
}