import baseService from './baseService';

const comissionService = {
  get: async (type: string, params: any) => baseService.httpGet(`Sale/GetComissionSellerSalesClosed/${type}`, params),
  getMessages: (type: string, response: any) => {
    switch (type) {
      case 'GET':
        return {
          error: 'Se produjo un error al intentar obtener las comisiones',
        };
      case 'REMOVE':
        return {
          success: 'Venta eliminada correctamente',
          error: 'Se produjo un error al intentar eliminar la comision.',
        };
      case 'UPDATE':
        return {
          success: 'Venta actualizada correctamente',
          error: 'Se produjo un error al intentar actualizar la comision',
        };
      case 'ADD':
        return {
          success: 'Venta creada correctamente',
          error: 'Se produjo un error al intentar crear la comision',
        };
      default:
        return {
          error: 'Se produjo un error desconocido',
        };
    }
  },
};

export default comissionService;
