import GoogleMapReact from 'google-map-react';
import { MapMarker } from '../../../components';
import config from '../../../config';

let mapCenter = {
  lat: -34.664796,
  lng: -58.532447,
};

const key = String(config.GoogleKey);

type location = {
  lat: number;
  lng: number;
}

type Props = {
  data: location;
  handleLocation: Function;
};

const Map = ({ data, handleLocation }: Props) => {

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key }}
      defaultZoom={12}
      defaultCenter={mapCenter}
      onClick={e => {handleLocation(e);}}
    >
        <MapMarker
          title={`Ubicacion`}
          lat={data.lat || mapCenter.lat}
          lng={data.lng || mapCenter.lng}
        />
    </GoogleMapReact>
  );
};

export default Map;
