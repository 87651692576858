const SELL_STATUSES = {
	pending: 1,
	approved: 2,
	rejected: 3
}

const zoneOptions = [
	{ label: 'Oeste', value: 1 },
	{ label: 'Sur', value: 2 },
	{ label: 'Norte', value: 3 },
	{ label: 'CABA', value: 4 },
	{ label: 'Depósito', value: 5 },
	{ label: 'La Plata', value: 6 }
]
const deliveryTimeOptions = [
	{ label: '9 a 13', value: '9 a 13hs' },
	{ label: '13 a 18', value: '13 a 18hs' },
	{ label: 'Sin Horario', value: 'Sin Horario' }
]

const buyerTypeOptions = [
	{ label: 'Minorista', value: 1 },
	{ label: 'Mayorista', value: 2 },
	{ label: 'Mayorista 2', value: 4 },
	// { label: 'Mayorista 3', value: 5 },
	{ label: 'Redes', value: 6 },
	{ label: 'Service', value: 3 }
]

const sellStatusOptions = [
	{ label: 'Pendiente', value: SELL_STATUSES.pending },
	{ label: 'Aprobada', value: SELL_STATUSES.approved },
	{ label: 'Rechazada', value: SELL_STATUSES.rejected }
]

const deliveryStatusOptions = [
	{ label: 'SinEstado', value: 1 },
	{ label: 'Entregado', value: 2 },
	{ label: 'Cancelado', value: 3 },
	{ label: 'Reprogramado', value: 4 }
]

export {
	zoneOptions, deliveryTimeOptions, buyerTypeOptions, sellStatusOptions,
	deliveryStatusOptions, SELL_STATUSES
}
