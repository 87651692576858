import { VariantType, useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

const useNotifications = () => {

	const { enqueueSnackbar } = useSnackbar()
	const { state: locationState }: any  = useLocation()

	const showNotification = (variant: VariantType, message: string) => {
		enqueueSnackbar(message, { variant });
	}

	const showLocationNotifications = () => {
		if(!locationState?.notifications) return;
		locationState.notifications.forEach((x: any) => {
			showNotification(x.type, x.message)
		})
	}

	return { showLocationNotifications, showNotification }
}

export default useNotifications
