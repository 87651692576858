import { useState } from 'react';
import { ConfirmationDialog } from '../../../components';
import { IconButton, Tooltip } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

type Props = {
  params: any;
  onDelivery: (x: GridCellParams) => void;
};

const DeliveryStateAction = ({ params, onDelivery }: Props) => {
  const [deliveryConfirmationOpen, setDeliveryConfirmationOpen] = useState(false);

  return (
    <>
      <Tooltip title="Entregar venta">
        <IconButton
          aria-label="approve"
          onClick={() => setDeliveryConfirmationOpen(true)}
        >
          <EmojiPeopleIcon color="primary" />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        isOpen={deliveryConfirmationOpen}
        handleConfirmation={() => {
          setDeliveryConfirmationOpen(false);
          onDelivery(params);
        }}
        handleRejection={() => setDeliveryConfirmationOpen(false)}
        message={`Estás seguro que deseas entregar la venta N° ${params.row.nroVenta}?`}
        description=""
        confirmMessage="Entregar venta"
        cancelMessage="Volver"
      />
    </>
  );
};

export default DeliveryStateAction;
