import { CellHead, RowBody } from '../../../components/grid/CustomGrid/types';
import {colorOptions} from '../../users/form/options';

type Params = {
  rows: any[];
  type: string;
};

export function GetDataBody({rows, type}:Params) {
  let dataBody: RowBody[] = [];

  function GetRow(row:any, type: string): any[] {
    return type === 'seller' ?
    [
      {
        cells: [
          {key: 'id', field:row.id},
          {key: 'fullname', field:row.fullname},
          {key: 'comission', field:row.comission},
          {key: 'collaboratorName', field: row.collaboratorName || ''},
          {key: 'collaboratorColor', field: colorOptions.find((color: any)=>color.value===row.collaboratorColor)?.label}
        ],
        tableRowProps: {
          hover: true
        }
      }
    ]
    :
    [
      {
        cells: [
          {key: 'id', field:row.id},
          {key: 'fullname', field:row.fullname},
          {key: 'comission', field:row.comission}
        ],
        tableRowProps: {
          hover: true
        }
      }
    ]
  }
  rows.map((row:any) => {
    GetRow(row, type).map((data: any)=>dataBody.push(data));
  });
  return dataBody;
}

export const getDataHead = () => {
  let dataHeadComisiones = sessionStorage.getItem('dataHeadComisiones');
  if (dataHeadComisiones) {
    return JSON.parse(dataHeadComisiones)
  }
  return dataHead
}

export const dataHead: CellHead[] = [
  {key: 'id', field:"id", tableCellProps: {align:"center", width: 40, className:"separador stickySaeta"}},
  {key: 'fullname', field:"Nombre", tableCellProps: {align:"center", width: 250,  className:"separador"}},
  {key: 'comission', field:"Comisión", tableCellProps: {align:"center", width: 250,  className:"separador"}},
  {key: 'collaboratorName', field:"Colaborador", tableCellProps: {align:"center", width: 250,  className:"separador"}},
  {key: 'collaboratorColor', field:"Color", tableCellProps: {align:"center", width: 50}}
];

export const headerExport: any[] = [
  {key: 'id', label: 'id'},
  {key: 'fullname', label: 'Nombre'},
  {key: 'comission', label: 'Comisión'},
  {key: 'collaboratorName', label: 'Colaborador'},
  {key: 'collaboratorColor', label: 'Color'},
];

export function getDataExport(rows:any[]) {
  let dataBody: any[] = [];

  rows.map((row:any) => (dataBody.push(
    {
      id:row.id, 
      fullname:row.fullname,
      comission:row.comission,
      collaboratorName:row.collaboratorName,
      collaboratorColor:row.collaboratorColor
    }
  )));
  return dataBody;
}

export function getDataSubFooter(rows:any): RowBody[] {
  let subTotal=0;
  rows.map((row:any) => (subTotal += row.comission));
	return [
		{
			cells: [
				{key: 'space', field:"", tableCellProps: {colSpan:1}},
				{key: 'titleTotal', field:"Total"},
				{key: 'totalPrice', field:subTotal.toFixed(2), tableCellProps: {align:"right"}},
			]
		}
	]
}