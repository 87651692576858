import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';
import { useState } from 'react';
import { ConfirmationDialog } from '../.';

type Props = {
  params: any;
  handleEdit: ((x: GridCellParams) => void) | null;
  handleDelete: ((x: GridCellParams) => void) | null;
};

const GridActions = ({ params, handleEdit, handleDelete }: Props) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const onDelete = async () => {
    setConfirmationOpen(true);
  };

  return (
    <>
      {handleEdit && (
        <IconButton aria-label="edit" onClick={() => handleEdit(params)}>
          <EditIcon color="secondary" />
        </IconButton>
      )}
      {handleDelete && (
        <IconButton aria-label="delete" onClick={() => onDelete()}>
          <DeleteIcon color="error" />
        </IconButton>
      )}

      <ConfirmationDialog
        isOpen={confirmationOpen}
        handleConfirmation={() => {
          setConfirmationOpen(false);
          if (handleDelete) {
            handleDelete(params);
          }
        }}
        handleRejection={() => setConfirmationOpen(false)}
        message="Estás seguro que deseas eliminar este registro?"
        description="Cuidado! Esta acción es irreversible."
        confirmMessage="Eliminar"
        cancelMessage="Cancelar"
      />
    </>
  );
};

export default GridActions;
