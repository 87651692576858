import { BaseLayout } from './components'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { useThemeMode } from './hooks'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router } from "react-router-dom"
import RouterSwitch from './RouterSwitch'
import RouterLinkList from './RouterLinkList'
import { createContext, useReducer, useEffect } from 'react'
import { LoginScreen } from './screens'
export const AuthContext = createContext<any>({ dispatch: () => {}, state: {} })

	const initialState = {
		isAuthenticated: false,
		user: null,
		token: null,
	};
	const reducer = (state: any, action: any) => {
	switch (action.type) {
		case "LOGIN":
			localStorage.setItem("userState", JSON.stringify(action.payload));
			return {
				...state,
				isAuthenticated: true,
				token: action.payload.token,
				id: action.payload.id,
				user: action.payload.user,
				type: action.payload.type,
				idProfile: action.payload.idProfile,
				fullName: action.payload.fullName,
				categories: action.payload.categories,
				listPrice: action.payload.listPrice
			};
			case "LOGOUT":
				localStorage.clear();
				return {
					...state,
					isAuthenticated: false,
					user: null
				};
			default:
				return state;
		}
	};


const App = () => {
	const [theme, toggleThemeMode]: any = useThemeMode()
	const themeConfig = createMuiTheme(theme)

	// move to auth
	const [userState, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const state = localStorage.getItem('userState')
		if(state) dispatch({ type: 'LOGIN', payload: JSON.parse(state) })
	}, [])

	return (
		<AuthContext.Provider
			value={{
				dispatch,
				userState
			}}
		>
		<MuiThemeProvider theme={themeConfig}>
			<SnackbarProvider
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				maxSnack={5}
			>
				<CssBaseline />
				{ !userState.isAuthenticated
					? <LoginScreen />
					: <Router>
						<BaseLayout
							handleToggleThemeMode={toggleThemeMode}
							routerLinkList={<RouterLinkList />}
						>
							<RouterSwitch />
						</BaseLayout>
					</Router>
				}
			</SnackbarProvider>
		</MuiThemeProvider>
		</AuthContext.Provider>
	)
}

export default App
