export const encode_utf8 = (s) => {
    try {
        return encodeURIComponent(s);
    } catch {
        return s;
    }
}
  
export const decode_utf8 = (s) => {
    try {
        return decodeURIComponent(s);
    } catch {
        return s;
    }
}