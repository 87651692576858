import { GridCellParams } from '@material-ui/data-grid';
import { GridHeader, LoadingIndicator } from '../../../components'
import { useHistory } from 'react-router-dom';
import { useCRUD, useNotifications } from '../../../hooks';
import { productsService } from '../../../services';
import { AuthContext } from '../../../App';
import { useContext, useState } from 'react';
import {getDataHead, GetDataBody, headerExport, getDataExport} from './columnsGetter';
import {getDataHead2, GetDataBody2, headerExport2, getDataExport2} from './columnsGetter';
import { CustomGridTable } from "../../../components/grid/CustomGrid";
import { changeViewColumn } from '../../../components/grid/CustomGrid/Funtions';
//$MYHASH$V1$10000$MOIDxkkwQuRPwd6BN9GAFcP/FQ+3RbKtmPtXOVPuMJG46X/f
const ProductsGrid = () => {

	const history = useHistory()
	const { showNotification } = useNotifications()
	const { userState } = useContext(AuthContext);

	const { handleRemove, loading, state: rows } = useCRUD(
		productsService.get,
		productsService.add,
		productsService.edit,
		productsService.remove,
		showNotification,
		productsService.getMessages
	)

	const onEdit = (params: GridCellParams) => {
		history.push('/productos/editar', { ...params.row }
	)}
  
	const querystring = window.location.search
	const params = new URLSearchParams(querystring)

    const [dataHeadColumns, setDataHeadColumns] = useState(userState.type === 'collaborator' ? getDataHead2() : getDataHead());
  
    const handleChangeViewColumn = (key: string) => {
      changeViewColumn(key, "dataHeadProductos", dataHeadColumns, setDataHeadColumns)
    }
    
    const [page, setPage] = useState(parseInt(params.get('page') || "0"));
    const changePage = (newPage: number) => {
      history.push(`?page=${newPage}`)
      setPage(newPage);
    }

	return (
		<>
			<LoadingIndicator loading={loading} />
			<GridHeader
				addUrl="productos/agregar"
				title="Productos"
				buttonText="Nuevo producto"
				button2Text="Actualización masiva"
				button2Action="/productos/actualizacion-masiva"
			/>
			<CustomGridTable
				cellsHead={dataHeadColumns} 
				rowsBody={userState.type === 'collaborator' ? GetDataBody2({rows,
					onEdit:()=>{},
					onDelete: handleRemove,
				}) : GetDataBody({rows,
					onEdit,
					onDelete: handleRemove,
				})} 
				pageSize={10} 
				pageParent={page}
				changePage={changePage}
				tableProps={{stickyHeader: true, size:"small"}}
				rowsPerPageOptions={[5, 10, 25]}
				toolbarProps={{
					exportProps: {
					data:userState.type === 'collaborator' ? getDataExport2(rows) : getDataExport(rows), headers:userState.type === 'collaborator' ? headerExport2 : headerExport, filename:"Productos.csv", name:"Productos"
					},
					filterProps: {
					columns: dataHeadColumns,
					handleChangeViewColumn
					}
				}}
				orderDefault={"asc"}
				orderByDefault={dataHeadColumns[0].key}
				idStoreFilter="filtrosProductos"
			/>
		</>
	)
}

export default ProductsGrid