import { Switch, Route } from 'react-router-dom';
import {
  UsersFormScreen,
  UsersGridScreen,
  CategoriesGridScreen,
  CategoriesFormScreen,
  ProductsGridScreen,
  ProductsFormScreen,
  ProductsUpdateScreen,
  ReparationsGridScreen,
  SubCategoriesGridScreen,
  SubCategoriesFormScreen,
  SellsGridScreen,
  SellsFormScreen,
  SellsDetailScreen,
  SellsDispatchScreen,
  DailyListGridScreen,
  CommissionGridScreen
} from './screens';

const RouterSwitch = () => {
  return (
    <Switch>
      {/* Users */}
      <Route path="/usuarios/editar">
        <UsersFormScreen mode="edit" />
      </Route>
      <Route path="/usuarios/agregar">
        <UsersFormScreen mode="add" />
      </Route>
      <Route path="/usuarios">
        <UsersGridScreen />
      </Route>

      {/* Categories */}
      <Route path="/categorias/editar">
        <CategoriesFormScreen mode="edit" />
      </Route>
      <Route path="/categorias/agregar">
        <CategoriesFormScreen mode="add" />
      </Route>
      <Route path="/categorias">
        <CategoriesGridScreen />
      </Route>

      {/* Sub-Categories */}
      <Route path="/sub-categorias/editar">
        <SubCategoriesFormScreen mode="edit" />
      </Route>
      <Route path="/sub-categorias/agregar">
        <SubCategoriesFormScreen mode="add" />
      </Route>
      <Route path="/sub-categorias">
        <SubCategoriesGridScreen />
      </Route>

      {/* Products */}
      <Route path="/productos/editar">
        <ProductsFormScreen mode="edit" />
      </Route>
      <Route path="/productos/agregar">
        <ProductsFormScreen mode="add" />
      </Route>
      <Route path="/productos/actualizacion-masiva">
        <ProductsUpdateScreen mode="add" />
      </Route>
      <Route path="/productos">
        <ProductsGridScreen />
      </Route>

      {/* Sells */}
      <Route path="/ventas/editar">
        <SellsFormScreen />
      </Route>
      <Route path="/ventas/:id/detalle">
        <SellsDetailScreen />
      </Route>
      <Route path="/ventas/:nroVenta/remito">
        <SellsDispatchScreen />
      </Route>
      <Route path="/ventas/agregar">
        <SellsFormScreen />
      </Route>
      <Route path="/ventas">
        <SellsGridScreen />
      </Route>

      {/* Reparations */}
      <Route path="/reparaciones">
        <ReparationsGridScreen />
      </Route>

      {/* Daily List */}
      <Route path="/listado-diario/editar">
        <SellsFormScreen />
      </Route>
      <Route path="/listado-diario/:id/detalle">
        <SellsDetailScreen />
      </Route>
      <Route path="/listado-diario/agregar">
        <SellsFormScreen />
      </Route>
      <Route path="/listado-diario">
        <DailyListGridScreen />
      </Route>

      {/* Commission */}
      <Route path="/comisiones">
        <CommissionGridScreen />
      </Route>

      {/* Home */}
      <Route path="/">
        <h1>Inicio</h1>
      </Route>
    </Switch>
  );
};

export default RouterSwitch;
