type Numerable = {
	[x: number]: string
}

export const zones: Numerable = {
	1: 'Oeste',
	2: 'Sur',
	3: 'Norte',
	4: 'CABA',
	5: 'Depósito',
	6: 'La Plata'
}

export const deliveryStatus: Numerable = {
	1: 'SinEstado',
	2: 'Entregado',
	3: 'Cancelado',
	4: 'Reprogramado'
}

export const buyerTypes: Numerable = {
	1: 'Minorista',
	2: 'Mayorista',
	4: 'Mayorista 2',
	5: 'Mayorista 3',
	6: 'Redes',
	3: 'Service'
}

export const sellSatus: Numerable = {
	1: 'Pendiente',
	2: 'Aprobada',
	3: 'Rechazada'
}

export const DELIVERY_STATUSES = {
	stateless: 1,
	delivered: 2,
	canceled: 3,
	reprogrammed: 4
}
