import { GridCellParams } from '@material-ui/data-grid';
import {
  GridHeader,
  LoadingIndicator,
} from '../../../components';
import {getDataHead, GetDataBody, headerExport, getDataExport, headerExportColaborador, getDataExportColaborador} from './columnsGetter';
import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCRUD, useNotifications } from '../../../hooks';
import { AuthContext } from '../../../App';
import { sellsService, usersService } from '../../../services';
import { SELL_STATUSES } from '../form/options';
import { CustomGridTable } from "../../../components/grid/CustomGrid";
import { changeViewColumn } from '../../../components/grid/CustomGrid/Funtions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; 
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import 'moment/locale/es';
import { DELIVERY_STATUSES } from '../../../constants';

const SellsGrid = () => {
  const history = useHistory();
  const { showNotification } = useNotifications();
  const [rows, setRows] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [deliveryMans, setDeliveryMans] = useState([]);
  const [loadingDeliveryMans, setLoadingDeliveryMans] = useState(true);
  let dataToHeadVentas = sessionStorage.getItem('filterToDateVentas');
  let dataFromHeadVentas = sessionStorage.getItem('filterFromDateVentas');
	const [fromDate, setFromDate] = useState(moment(dataFromHeadVentas ? dataFromHeadVentas : undefined).format("YYYY-MM-DD 00:00:00"));
	const [toDate, setToDate] = useState(moment(dataToHeadVentas ? dataToHeadVentas : undefined).format("YYYY-MM-DD 23:59:59"));

  useEffect(() => {
    let dataToHeadVentas = sessionStorage.getItem('filterToDateVentas');
    if (dataToHeadVentas) {
      setToDate(dataToHeadVentas);
    }
    let dataFromHeadVentas = sessionStorage.getItem('filterFromDateVentas');
    if (dataFromHeadVentas) {
      setFromDate(dataFromHeadVentas);
    }
  }, [])

  useEffect(() => {
    usersService.getDeliverymans().then((d)=>setDeliveryMans(d)).finally(()=>{
      setLoadingDeliveryMans(false);
      sellsService.getSalesClosed({fromDate:moment(fromDate).format("YYYY-MM-DD 00:00:00"),toDate:moment(toDate).format("YYYY-MM-DD 23:59:59")}).then((r)=>setRows(r)).finally(()=>setLoading(false));
    })
  }, [fromDate,toDate])

  const {
    handleRemove
  } = useCRUD(
    async () => {},
    sellsService.add,
    sellsService.edit,
    sellsService.remove,
    showNotification,
    sellsService.getMessages,
    (sell: any) => sell.nroVenta
  );

  const deliveryManOptions = deliveryMans.map((x: any) => ({
    value: x.id,
    description: `${x.firstName} ${x.lastName}`,
  }));
  
  const querystring = window.location.search
  const params = new URLSearchParams(querystring)

  const { userState } = useContext(AuthContext);

  const onEdit = (params: GridCellParams) => {
    history.push('/ventas/editar', { ...params.row });
  };

  const updateStatus = (params: GridCellParams, newStatusValue: number) => {
    const sellIndex = rows.findIndex(
      (row:any) => row.nroVenta === params.row.nroVenta
    );
    let newSells = [...rows];
    if (newStatusValue===SELL_STATUSES.rejected) {
      newSells[sellIndex] = { ...newSells[sellIndex], salestate: newStatusValue, deliverystate: DELIVERY_STATUSES.canceled};
    } else {
      newSells[sellIndex] = { ...newSells[sellIndex], salestate: newStatusValue };
    }
    setRows(newSells);
  };

  const onCancel = async (params: GridCellParams) => {
    try {
      setLoading(true);
      const nroVenta = params.row.nroVenta;
      await sellsService.changeStatus(nroVenta, SELL_STATUSES.rejected);
      // await sellsService.changeDeliveryState(nroVenta, DELIVERY_STATUSES.canceled,"Venta cancelada");

      updateStatus(params, SELL_STATUSES.rejected);
      showNotification('success', 'Venta cancelada correctamente');
    } catch (e) {
      showNotification('error', 'Se produjo un error al cancelar la venta');
    } finally {
      setLoading(false);
    }
  };

  const onApprove = async (params: GridCellParams) => {
    try {
      setLoading(true);
      const nroVenta = params.row.nroVenta;
      await sellsService.changeStatus(nroVenta, SELL_STATUSES.approved);

      updateStatus(params, SELL_STATUSES.approved);
      showNotification('success', 'Venta aprobada correctamente');
    } catch (e) {
      showNotification('error', 'Se produjo un error al aprobar la venta');
    } finally {
      setLoading(false);
    }
  };

  const onAssignDeliveryMan = async (
    sellNumber: number,
    deliveryManId: number
  ) => {
    try {
      setLoading(true);
      await sellsService.assignDeliveryMan(sellNumber, deliveryManId);
      const sellIndex = rows.findIndex((row:any) => row.nroVenta === sellNumber);
      const deliveryManIndex = deliveryMans.findIndex(
        (row: any) => row.id === deliveryManId
      );
      const newDeliveryMan = deliveryMans[deliveryManIndex];
      let newSells = [...rows];
      newSells[sellIndex] = {
        ...newSells[sellIndex],
        deliveryMan: newDeliveryMan,
      };
      setRows(newSells);
      setLoading(false);
      showNotification('success', 'Repartidor asignado correctamente');
    } catch (e) {
      showNotification(
        'error',
        'Se produjo un error al intentar asignar al repartidor'
      );
    }
  };

  const onDeliveryStatus = async (
    params: GridCellParams
  ) => {
    try {
      setLoading(true);
      await sellsService.changeDeliveryState(params.row.nroVenta, 2,'Entregado');
      const sellIndex = rows.findIndex((row:any) => row.nroVenta === params.row.nroVenta);
      let newSells = [...rows];
      newSells[sellIndex] = {
        ...newSells[sellIndex],
        deliverystate: 2,
      };
      setRows(newSells);
      setLoading(false);
      showNotification('success', 'Venta entregada correctamente');
    } catch (e) {
      showNotification(
        'error',
        'Se produjo un error al intentar entregar la venta'
      );
    }
  };

  const allowAdd =
    userState.type === 'admin' || userState.type === 'collaborator' || userState.type === 'seller';

  const [dataHeadColumns, setDataHeadColumns] = useState(getDataHead(userState.type));

  const handleChangeViewColumn = (key: string) => {
    changeViewColumn(key, "dataHeadVentas", dataHeadColumns, setDataHeadColumns)
  }
  
  const setOpen = (id: any, open:boolean) => {
    let newSells = [...rows];
    let sellIndex = rows.findIndex(
      (row:any) => row.nroVenta === id
    );
    newSells[sellIndex] = {
      ...newSells[sellIndex],
      open: open,
    };
    setRows(newSells);
  }
  
  const [page, setPage] = useState(parseInt(params.get('page') || "0"));
  const changePage = (newPage: number) => {
    history.push(`?page=${newPage}`)
    setPage(newPage);
  }

  return (
    <>
      <LoadingIndicator loading={loading || loadingDeliveryMans} />

      <GridHeader
        addUrl="ventas/agregar"
        title="Ventas"
        buttonText={allowAdd ? 'Nueva venta' : ''}
      >
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={'Saeta'}
              views={['day', 'month', 'year']}
              label="Desde"
              inputFormat="dd/MM/yyyy"
              value={moment(fromDate).isValid() ? moment(fromDate).format("YYYY-MM-DD 00:00:00") : null}
              onChange={(newValue) => {
                setFromDate(moment(newValue).isValid() ? moment(newValue).format("YYYY-MM-DD 00:00:00") : "");  
                sessionStorage.setItem('filterFromDateVentas',moment(newValue).isValid() ? moment(newValue).format("YYYY-MM-DD 00:00:00") : "");
              }}
              renderInput={(params) => <TextField
                className={'Saeta'} color="primary" {...params} inputProps={{...params.inputProps, style:{color:"white", fill:'white'}}} InputLabelProps={{...params.InputLabelProps, style:{color:"white", fill:'white'}}} InputProps={{...params.InputProps, style:{color:"white", fill:'white'}}} helperText={null} />}
            />
            <DatePicker
              views={['day', 'month', 'year']}
              label="Hasta"
              inputFormat="dd/MM/yyyy"
              className={'Saeta'}
              value={moment(toDate).isValid() ? moment(toDate).format("YYYY-MM-DD 23:59:59") : null}
              onChange={(newValue) => {
                setToDate(moment(newValue).isValid() ? moment(newValue).format("YYYY-MM-DD 23:59:59") : "");  
                sessionStorage.setItem('filterToDateVentas',moment(newValue).isValid() ? moment(newValue).format("YYYY-MM-DD 23:59:59") : "");
              }}
              renderInput={(params) => <TextField
                className={'Saeta'} color="primary" {...params} inputProps={{...params.inputProps, style:{color:"white"}}} InputLabelProps={{...params.InputLabelProps, style:{color:"white"}}} InputProps={{...params.InputProps, style:{color:"white"}}} helperText={null} />}
            />
          </LocalizationProvider>
        </>
      </GridHeader>

      <CustomGridTable
          cellsHead={dataHeadColumns} 
          rowsBody={GetDataBody({rows,
            onEdit,
            onDelete: handleRemove,
            onApprove,
            onCancel,
            onAssignDeliveryMan,
            deliveryManOptions,
            userType: userState.type,
            setOpen,
            onDeliveryStatus
          })} 
          pageSize={10} 
          pageParent={page}
          changePage={changePage}
          tableProps={{stickyHeader: true, size:"small"}}
          rowsPerPageOptions={[5, 10, 25]}
          toolbarProps={{
            exportProps: {
              data:userState.type==="collaborator" ? getDataExportColaborador(rows) : getDataExport(rows,userState.type), headers:userState.type==="collaborator" ? headerExportColaborador : headerExport, filename:"Ventas.csv", name:"Ventas"
            },
            filterProps: {
              columns: dataHeadColumns,
              handleChangeViewColumn
            }
          }}
          orderDefault={"desc"}
          orderByDefault={dataHeadColumns[0].key}
          idStoreFilter="filtrosVentas"
      />
    </>
  );
};

export default SellsGrid;
