import { useContext, useState } from 'react';
import { AuthContext } from '../../../App';
import { FormControlLabel, Checkbox, Paper } from '@material-ui/core';
import { CustomGridTable } from "../../../components/grid/CustomGrid";
import { CellHead, RowBody } from '../../../components/grid/CustomGrid/types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

type Props = {
  selectedProducts: any;
  products: any;
  customerInformation: any;
  deliveryDate: string;
  sellDate: string;
  handleDateChange: any;
};

const BagReview = ({
  products,
  selectedProducts,
  customerInformation,
  deliveryDate,
  sellDate,
  handleDateChange,
}: Props) => {
  const { userState } = useContext(AuthContext);

  const [returnProduct, setReturnProduct] = useState(false);

  const getProductById = (id: any) => {
    return products.find((x: any) => x.id === parseInt(id));
  };

  let subTotal: number = 0;
  Object.keys(selectedProducts).map((x: any) => (
    subTotal += (customerInformation.typeclient === 1 ? getProductById(x).retailPrice : getProductById(x).fullPrice)*selectedProducts[x]
  ));

    
  function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
  }

	let dataHead: CellHead[] = [
		{field:"Nombre de Producto"},
		{field:"Descripción"},
		{field:"Categoría"},
		{field:"Subcategoría"},
		{field:"Cantidad"},
		{field:"Precio"}
	];

	let dataBody: RowBody[] = []
	Object.keys(selectedProducts).map((x:any) => (dataBody.push(
		{
			cells: [
				{key: 'name', field:getProductById(x).name, tableCellProps: {component:"th", scope:"row"}},
				{key: 'description', field:getProductById(x).description},
				{key: 'category', field:getProductById(x).category.name},
				{key: 'subCategory', field:getProductById(x).subCategory.name},
				{key: 'cantidad', field:selectedProducts[x]},
				{key: 'price', tableCellProps: {align:"right"}, field:customerInformation.typeclient === 1
					? ccyFormat(getProductById(x).retailPrice*selectedProducts[x])
					: ccyFormat(getProductById(x).fullPrice*selectedProducts[x])},
			]
		}
	)));

	let dataSubFooter: RowBody[] = [
		{
			cells: []
		}
	]

	dataSubFooter.push(
		{
			cells: [
				{key: 'space', field:"", tableCellProps: {rowSpan:4, colSpan:4}},
				{key: 'subtotal', field:"Subtotal"},
				{key: 'subTotal', field:ccyFormat(subTotal).toString(), tableCellProps: {align:"right"}},
			]
		}
	)
	dataSubFooter.push(
		{
			cells: [
				{key:'title', field:"Delivery"},
				{key: 'deliveryPrice', field:ccyFormat(customerInformation.deliverycost||0), tableCellProps: {align:"right"}},
			]
		}
	)
	dataSubFooter.push(
		{
			cells: [
				{key: 'titleTotal', field:"Total"},
				{key: 'totalPrice', field:ccyFormat(subTotal+customerInformation.deliverycost), tableCellProps: {align:"right"}},
			]
		}
	)
  const [selectedSellDate, setSelectedSellDate] = useState(new Date(sellDate) || new Date());
  const changeSellDate = (e:any, id:string) => {
    handleDateChange(`${e.getFullYear()}-${e.getMonth()<9?0:''}${e.getMonth()+1}-${e.getDate()<10?0:''}${e.getDate()}`,id);
    setSelectedSellDate(e);
  }
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(new Date(deliveryDate) || new Date());
  const changeDeliveryDate = (e:any, id:string) => {
    handleDateChange(`${e.getFullYear()}-${e.getMonth()<9?0:''}${e.getMonth()+1}-${e.getDate()<10?0:''}${e.getDate()}`,id);
    setSelectedDeliveryDate(e);
  }

  return (
    <section style={{ margin: 'auto', marginBottom: 32 }}>
      <Paper
        elevation={2}
        style={{
          padding: 8,
          marginBottom: 24,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <h3>
          Fecha de venta:
        </h3>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="sellDate"
              value={selectedSellDate}
              onChange={(e) => changeSellDate(e, 'sellDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>

        <h3>
          Fecha de entrega:{' '}
        </h3>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="deliverydate"
              value={selectedDeliveryDate}
              onChange={(e) => changeDeliveryDate(e, 'deliverydate')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDate={selectedSellDate}
              maxDate={new Date(selectedSellDate.getFullYear(), selectedSellDate.getMonth(),selectedSellDate.getDate()+((selectedSellDate.getDay()===3 || selectedSellDate.getDay()===4 || selectedSellDate.getDay()===5 || selectedSellDate.getDay()===6)?5:4))}
            />
          </MuiPickersUtilsProvider>

        <h3>Vendedor: {userState?.user?.split('@')[0] || ''}</h3>
        <h3>
          Tipo de venta:{' '}
          {customerInformation.typeclient === 3 ? 'Servicio' : 'Normal'}
        </h3>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={returnProduct}
              onChange={() => setReturnProduct((x) => !x)}
              name="returnProduct"
            />
          }
          label="Devuelve producto"
        />
      </Paper>

      <CustomGridTable
        cellsHead={dataHead} 
        rowsBody={dataBody} 
        rowsSubFooter={dataSubFooter} 
        tableProps={{stickyHeader: true}}
      />
    </section>
  );
};

export default BagReview;
