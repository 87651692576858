import { GridCellParams } from '@material-ui/data-grid';
import { GridHeader, LoadingIndicator } from '../../../components';
import {getDataHead, GetDataBody, headerExport, getDataExport} from './columnsGetter';
import { CustomGridTable } from "../../../components/grid/CustomGrid";
import { changeViewColumn } from '../../../components/grid/CustomGrid/Funtions';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNotifications, useCRUD } from '../../../hooks';
import { AuthContext } from '../../../App';
import { usersService } from '../../../services';

const UsersGrid = () => {
  const history = useHistory();
  const { showNotification } = useNotifications();
  const { userState } = useContext(AuthContext);

  const {
    handleRemove,
    loading,
    state: rows,
    setState,
  } = useCRUD(
    userState.type === 'collaborator'
      ? async () => usersService.getSellersOfCollaborator(userState.id)
      : usersService.get,
    usersService.add,
    usersService.edit,
    usersService.remove,
    showNotification,
    usersService.getMessages
  );
  
	const querystring = window.location.search
	const params = new URLSearchParams(querystring)

  const [dataHeadColumns, setDataHeadColumns] = useState(getDataHead());

  const handleChangeViewColumn = (key: string) => {
    changeViewColumn(key, "dataHeadUsuarios", dataHeadColumns, setDataHeadColumns)
  }
  
  const [page, setPage] = useState(parseInt(params.get('page') || "0"));
  const changePage = (newPage: number) => {
    history.push(`?page=${newPage}`)
    setPage(newPage);
  }

  const onActive = async (params: any) => {
    try {
      await usersService.deactivateUser(params.id, !params.isActive);
      const newState = rows.map((x) =>
        x.id === params.id ? { ...x, isActive: !x.isActive } : x
      );
      setState(newState);
    } catch {
      showNotification('error', 'No se pudo desactivar al usuario');
    }
  };

  const onEdit = (params: GridCellParams) => {
    history.push('/usuarios/editar', { ...params.row });
  };
  return (
    <>
      <LoadingIndicator loading={loading} />
      <GridHeader
        addUrl="usuarios/agregar"
        title="Usuarios"
        buttonText="Nuevo usuario"
      />
      <CustomGridTable
        cellsHead={dataHeadColumns} 
        rowsBody={GetDataBody({rows,
          onActive,
          onEdit,
          onDelete: handleRemove,
          userType: userState.type,
        })} 
        pageSize={10} 
        pageParent={page}
        changePage={changePage}
        tableProps={{stickyHeader: true, size:"small"}}
        rowsPerPageOptions={[5, 10, 25]}
        toolbarProps={{
          exportProps: {
          data:getDataExport(rows), headers:headerExport, filename:"usuarios.csv", name:"usuarios"
          },
          filterProps: {
          columns: dataHeadColumns,
          handleChangeViewColumn
          }
        }}
        orderDefault={"desc"}
        orderByDefault={dataHeadColumns[5].key}
        idStoreFilter="filtrosUsuarios"
      />
    </>
  );
};

export default UsersGrid;
