import baseService from './baseService'

const categoriesService = {
	get: async() => baseService.httpGet('Category'),
	add: async(item: any) => baseService.httpPost('Category', item),
	edit: async(item: any) => baseService.httpPut('Category', item),
	remove: async(idCategory: any) => baseService.httpDelete('Category', {}, { idCategory }),
	getMessages: (type: string, response: any) => {
		switch(type){
			case 'GET': return {
				error: 'Se produjo un error al intentar obtener las categorías'
			}
			case 'REMOVE': return {
				success: 'Categoría eliminada correctamente',
				error: 'Se produjo un error al intentar eliminar la categoría.'
			}
			case 'UPDATE': return {
				success: 'Categoría actualizada correctamente',
				error: 'Se produjo un error al intentar actualizar la categoría'
			}
			case 'ADD': return {
				success: 'Categoría creada correctamente',
				error: 'Se produjo un error al intentar crear la categoría'
			}
			default: return {
				error: 'Se produjo un error desconocido'
			}
		}
	},
	getCategoriesAndSubCategories: async() => baseService.httpGet(
		'Category/GetCategoriesAndSubcategories'
	)
}

export default categoriesService
