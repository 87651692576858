import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useFormik } from 'formik';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ProductsGrid from './ProductsGrid';
import CustomerInformationForm from './CustomerInformationForm';
import { LoadingIndicator, Stepper } from '../../../components';
import BagReview from './BagReview';
import { productsService, sellsService } from '../../../services';
import { encode_utf8, useCRUD, useNotifications } from '../../../hooks';
import { validationSchema } from './validationSchema';
import { AuthContext } from '../../../App';
import { SELL_STATUSES } from '../form/options';
import { DELIVERY_STATUSES } from '../../../constants';
import parseISO from 'date-fns/parseISO';

const defaultValues = {
  firstname: '',
  lastname: '',
  dni: '',
  address: '',
  beetwenstreets: '',
  zone: '',
  locality: '',
  latitude: 0,
  longitude: 0,
  comments: '',
  deliveryhour: '',
  email: '',
  phone: '',
  typeclient: '',
  deliverycost: 0,
};

const icons: { [index: string]: React.ReactElement } = {
  1: <ListAltIcon />,
  2: <AssignmentIndIcon />,
  3: <ShoppingBasketIcon />,
};

const SellsForm = () => {
  const [selectedProducts, setSelectedProducts] = useState({});
  const { showNotification } = useNotifications();
  const [activeStep, setActiveStep] = useState(0);
  const { userState } = useContext(AuthContext);
  const { state: locationState }: any = useLocation();
  const [deliveryDate, setDeliveryDate] = useState<string>((new Date()).toISOString());
  const [sellDate, setSellDate] = useState<string>((new Date()).toISOString());
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [localityOptions, setLocalityOptions] = useState<any>([])

  const history = useHistory();

  useEffect(() => {
    if (!locationState?.nroVenta) return;
    let newSelectedProducts: any = {};
    locationState.productCar.forEach((x: any) => {
      newSelectedProducts[x.idProduct] = x.quantity;
    });
    setSelectedProducts(newSelectedProducts);
    handleLocation({ lat: JSON.parse(locationState.location).latitude, lng: JSON.parse(locationState.location).longitude });
    setValueAddress(locationState.client.address);
    locationState.client.deliverycost = locationState.deliverycost;
  }, []);

  const { loading, state: products } = useCRUD(
    productsService.get,
    productsService.add,
    productsService.edit,
    productsService.remove,
    () => { },
    productsService.getMessages
  );

  const formik = useFormik({
    initialValues: locationState?.client || defaultValues,
    validationSchema: validationSchema,
    onSubmit: async (_values) => {
      nextStep();
    },
  });

  const handleDateChange = (
    date: string,
    field: string
  ) => {
    if (field === 'sellDate') {
      setSellDate(date);
    } else {
      setDeliveryDate(date);
    }
  };

  const createSale = async () => {
    try {
      let clientData = {
        ...formik.values,
        locality: encode_utf8(localityOptions.find((l) => l.value === formik.values.locality).label)
      }
      const responseClient = await sellsService.addClient(clientData);
      const res = await responseClient.json();
      const { nroVenta: idSale, idClient } = res;
      if (!idClient) {
        showNotification('error', 'Hubo un error al crear cliente.');
      }
      else {
        const productCar = createProductCard(idSale);

        await sellsService.AddProductToCarWithList(productCar, clientData.typeclient);

        const { comments, zone, longitude, latitude, deliverycost } =
          formik.values;
        const location = { longitude, latitude };
        const sale = {
          nroVenta: idSale,
          idClient,
          zone,
          deliverycost,
          location: JSON.stringify(location),
          comments,
          salestate: SELL_STATUSES.pending,
          deliverystate: DELIVERY_STATUSES.stateless,
          saledate: parseISO(sellDate),
          deliverydate: parseISO(deliveryDate),
          idSeller: userState.id,
        };

        await sellsService.add(sale);

        showNotification('success', 'Venta creada correctamente');
        history.goBack();
      }
    } catch {
      showNotification('error', 'Hubo un error al procesar la venta.');
    }
  };

  const createProductCard = (nroVenta) => {
    return Object.entries(selectedProducts).filter((p: any) => p.Quantity !== 0).map(
      ([idProduct, Quantity]) => {
        let typeclient = formik.values.typeclient;
        let price = 0;
        let sellerComission = 0;
        let collaboratorComission = 0;
        switch (typeclient) {
          case 1:
            price = products.find((p: any) => p.id === parseInt(idProduct)).retailPrice;
            sellerComission = products.find((p: any) => p.id === parseInt(idProduct)).sellerCommissionRetail;
            collaboratorComission = products.find((p: any) => p.id === parseInt(idProduct)).collaboratorCommissionRetail;
            break;
          case 2:
            price = products.find((p: any) => p.id === parseInt(idProduct)).fullPrice;
            sellerComission = products.find((p: any) => p.id === parseInt(idProduct)).sellerCommissionFull;
            collaboratorComission = products.find((p: any) => p.id === parseInt(idProduct)).collaboratorCommissionFull;
            break;
          case 3:
            price = products.find((p: any) => p.id === parseInt(idProduct)).fullPrice;
            sellerComission = products.find((p: any) => p.id === parseInt(idProduct)).sellerCommissionFull;
            collaboratorComission = products.find((p: any) => p.id === parseInt(idProduct)).collaboratorCommissionFull;
            break;
          case 4:
            price = products.find((p: any) => p.id === parseInt(idProduct)).fullPrice2;
            sellerComission = products.find((p: any) => p.id === parseInt(idProduct)).sellerCommissionFull2;
            collaboratorComission = products.find((p: any) => p.id === parseInt(idProduct)).collaboratorCommissionFull2;
            break;
          case 5:
            price = products.find((p: any) => p.id === parseInt(idProduct)).fullPrice3;
            sellerComission = products.find((p: any) => p.id === parseInt(idProduct)).sellerCommissionFull3;
            collaboratorComission = products.find((p: any) => p.id === parseInt(idProduct)).collaboratorCommissionFull3;
            break;
          case 6:
            price = products.find((p: any) => p.id === parseInt(idProduct)).gridPrice;
            sellerComission = products.find((p: any) => p.id === parseInt(idProduct)).sellerCommissionGrid;
            collaboratorComission = products.find((p: any) => p.id === parseInt(idProduct)).collaboratorCommissionGrid;
            break;
          default:
            price = products.find((p: any) => p.id === parseInt(idProduct)).fullPrice;
            sellerComission = products.find((p: any) => p.id === parseInt(idProduct)).sellerCommissionFull;
            collaboratorComission = products.find((p: any) => p.id === parseInt(idProduct)).collaboratorCommissionFull;
            break;
        }
        return {
          idProduct: parseInt(idProduct),
          Quantity,
          idSale: nroVenta,
          price,
          sellerComission,
          collaboratorComission,
          cost: products.find((p: any) => p.id === parseInt(idProduct)).cost
        }
      }
    );
  }

  const updateSale = async () => {
    try {
      const client = {
        ...encode_utf8(formik.values),
        locality: encode_utf8(localityOptions.find((l) => l.value === formik.values.locality).label)
      };
      const productCar = createProductCard(locationState.nroVenta);

      const { comments, zone, longitude, latitude, deliverycost } =
        formik.values;
      const location = { longitude, latitude };
      const sale = {
        ...locationState,
        nroVenta: locationState.nroVenta,
        idClient: client.id,
        zone,
        deliverycost,
        location: JSON.stringify(location),
        comments,
        salestate: SELL_STATUSES.pending,
        saledate: parseISO(sellDate),
        deliverydate: parseISO(deliveryDate),
        idSeller: userState.id,
        client,
        productCar
      };

      await sellsService.edit(sale);

      showNotification('success', 'Venta actualizada correctamente');
      history.goBack();
    } catch {
      showNotification('error', 'Hubo un error al procesar la venta.');
    }
  };

  const nextStep = () => {
    setActiveStep((prevActiveStep: number): number => prevActiveStep + 1);
  };

  const handleNext = () => {
    if (activeStep === 0 && Object.values(selectedProducts).length === 0) {
      showNotification('error', 'Debe seleccionar al menos un producto.');
      return;
    }

    if (activeStep === 2) {
      if (!validateDeliveryDate()) return;
      (locationState && locationState.nroVenta) ?
        updateSale()
        :
        createSale();
      return;
    }

    nextStep();
  };

  const validateDeliveryDate = () => {
    if (deliveryDate) {
      return true;
    }

    showNotification('error', 'Seleccione una fecha de entrega.');
    return false;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        history.goBack();
        return 0;
      } else return prevActiveStep - 1;
    });
  };

  const handleLocation = (data: any) => {
    formik.values.latitude = data.lat;
    formik.values.longitude = data.lng;
    setLatitude(data.lat);
    setLongitude(data.lng);
  }

  const setValueAddress = (data: any) => {
    formik.values.address = data;
  }

  useEffect(() => {
    sellsService.getLocalitys({ idZone: formik.values.zone }).then(
      (data) => {
        var localidades = data.map((l: any) => {
          return ({ label: l.description, value: l.description });
        });
        setLocalityOptions(localidades);
      }
    ).catch(() => setLocalityOptions([]));
  }, [formik.values.zone])

  return (
    <Stepper
      handleNext={activeStep === 1 ? formik.handleSubmit : handleNext}
      handleBack={handleBack}
      activeStep={activeStep}
      steps={[
        'Seleccionar productos',
        'Datos del comprador',
        'Bolsa de compras',
      ]}
      confirmationText="Confirmar compra"
      icons={icons}
    >
      <LoadingIndicator loading={loading} />
      {activeStep === 0 ? (
        <ProductsGrid
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      ) : activeStep === 1 ? (
        <CustomerInformationForm formik={formik}
          handleLocation={handleLocation}
          setValueAddress={setValueAddress}
          localityOptions={localityOptions} />
      ) : (
        <BagReview
          products={products}
          selectedProducts={selectedProducts}
          customerInformation={formik.values}
          handleDateChange={handleDateChange}
          deliveryDate={deliveryDate}
          sellDate={sellDate}
        />
      )}
    </Stepper>
  );
};

export default SellsForm;
