import baseService from './baseService'

const categoriesService = {
	getByCategoryId: async(categoryId: any) => baseService.httpGet(`Category/GetSubcategories/${categoryId}`),
	add: async(item: any) => baseService.httpPost('SubCategory', item),
	edit: async(item: any) => baseService.httpPut('SubCategory', item),
	remove: async(idSubCategory: any) => baseService.httpDelete('SubCategory', {}, { idSubCategory }),
	getMessages: (type: string, response: any) => {
		switch(type){
			case 'GET': return {
				error: 'Se produjo un error al intentar obtener las sub-categorías'
			}
			case 'REMOVE': return {
				success: 'Sub-categoría eliminada correctamente',
				error: 'Se produjo un error al intentar eliminar la sub-categoría.'
			}
			case 'UPDATE': return {
				success: 'Sub-categoría actualizada correctamente',
				error: 'Se produjo un error al intentar actualizar la sub-categoría'
			}
			case 'ADD': return {
				success: 'Categoría creada correctamente',
				error: 'Se produjo un error al intentar crear la sub-categoría'
			}
			default: return {
				error: 'Se produjo un error desconocido'
			}
		}
	}
}

export default categoriesService
