import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
	}),
)

type Props = {
	loading: boolean
}

const LoadingIndicator = ({ loading }: Props) => {

	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={loading}>
			<CircularProgress color="inherit" />
		</Backdrop>
	)
}

export default LoadingIndicator
