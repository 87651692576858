import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingIndicator } from '../../../components';
import { profileOptions, colorOptions } from './options';
import { AuthContext } from '../../../App';
import { categoriesService, usersService } from '../../../services';
import { useCRUD, useNotifications } from '../../../hooks';
import {
  TextField, Grid, Select, MenuItem, FormControl
} from '@material-ui/core'
import { OptionType } from '../../../types/form';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";

const categoryOptionsMapper = (categories: any) => {
  return categories.map((x: any) => ({ label: x.name, value: x.id }));
};

const useStyles = makeStyles({
  root: {
    "&.Mui-selected": {
      backgroundColor: "lightgreen",
      color: "black"
    }
  }
});

const subCategoryOptionsMapper = (categories: any, selectedCategories: any) => {
  const category = categories.filter((x: any) => selectedCategories.includes(x.id));
  let result: OptionType[] = [];
  if (!category) return result;
  category.map(
    (x: any) => x.subcategories.map(
      (y: any) => {
        result.push({
          label: y.name,
          value: y.id,
        });
        return y;
      }
    )
  );
  return result;
};

type subCategories = {
  id: number;
  name: string;
  idCategory: number;
  SubCategoryDeleted: boolean;
}

type categories = {
  id: number;
  name: string;
  description: string;
  subcategories: subCategories[];
  profileUserLogin: number;
}

const UsersForm = ({ mode }: { mode: string }) => {
  const title = `${mode === 'add' ? 'Agregar' : 'Editar'} usuario`;
  const history = useHistory();
  const { state: locationState }: any = useLocation();
  const { userState } = useContext(AuthContext);
  const { showNotification } = useNotifications();
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState<categories[]>([]);
  const listPriceOptions: OptionType[] = ([
    {
      value: 1,
      label: "MINORISTA"
    },
    {
      value: 2,
      label: "MAYORISTA"
    },
    {
      value: 4,
      label: "MAYORISTA 2"
    },
    {
      value: 6,
      label: "Redes"
    },
    {
      value: 3,
      label: "SERVICE"
    }
  ]);

  const isCollaborator = userState.type === 'collaborator';

  const [firstName, setFirstName] = useState(locationState?.firstName || '');
  const [lastName, setLastName] = useState(locationState?.lastName || '');
  const [email, setEmail] = useState(locationState?.email || '');
  const [phone, setPhone] = useState(locationState?.phone || '');
  const [password, setPassword] = useState(locationState?.password || '');
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [idProfile, setIdProfile] = useState(locationState?.idProfile || 2);
  const [color, setColor] = useState(locationState?.color || 1);
  const [idCollaborator, setIdCollaborator] = useState(locationState?.idCollaborator || (isCollaborator ? userState.id : 1));
  const [idsCategory, setIdsCategory] = useState<any>(locationState?.idsCategory || []);
  const [idsSubCategory, setIdsSubCategory] = useState<any>(locationState?.idsSubCategory || []);
  const [listPrice, setListPrice] = useState(locationState?.listPrice || [1]);

  const {
    handleAdd,
    handleEdit,
    loading,
    setLoading,
  } = useCRUD(
    null,
    usersService.add,
    usersService.edit,
    usersService.remove,
    showNotification,
    usersService.getMessages
  );

  const filteredProfileOptions = !isCollaborator
    ? profileOptions
    : profileOptions.filter((profile) => profile.label === 'Vendedor');

  const getCollaboratorOptions = async () => {
    setLoading(true);
    try {
      const response = await usersService.getCollaborators();
      const newCollaborators = response.map((x: any) => ({
        label: `${x.firstName} ${x.lastName}`,
        value: x.id,
        color: colorOptions.find((c) => c.value === x.color)?.color,
      }));
      setCollaboratorOptions(newCollaborators);
    } catch (e) {
      showNotification(
        'error',
        usersService.getMessages('GET_COLLABORATORS', e).error
      );
    }
    setLoading(false);
  };

  const getCategoriesAndSubCategories = async () => {
    setLoading(true);
    try {
      const response = await categoriesService.getCategoriesAndSubCategories();
      setCategoriesOptions(response);
    } catch (e) {
      showNotification(
        'error',
        usersService.getMessages('GET_CATEGORIES', e).error
      );
    }
    setLoading(false);
  };

  const getCategoriesOfUser = async (idUser) => {
    setLoading(true);
    try {
      const response = await usersService.getCategoriesOfUser(idUser);
      let cat: number = 0;
      let scat: number = 0;
      let cats: number[] = [];
      let subCats: number[] = [];
      for await (const category of response) {
        if (category.idCategory != cat) {
          cat = category.idCategory;
          cats.push(cat);
        }
        if (category.idSubCategory != scat) {
          scat = category.idSubCategory;
          subCats.push(scat);
        }
      }
      setIdsCategory(cats);
      setIdsSubCategory(subCats);
    } catch (e) {
      showNotification(
        'error',
        usersService.getMessages('GET_CATEGORIES_OF_USER', e).error
      );
    }
    setLoading(false);
  };

  const classes = useStyles();

  const getListPriceOfUser = async (idUser) => {
    setLoading(true);
    try {
      const response = await usersService.getListPriceOfUser(idUser);
      let id: number = 0;
      let lists: number[] = [];
      for await (const result of response) {
        if (result.listPrice != id) {
          id = result.listPrice;
          lists.push(id);
        }
      }
      setListPrice(lists);
    } catch (e) {
      showNotification(
        'error',
        usersService.getMessages('GET_LISTPRICE_OF_USER', e).error
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    locationState?.id && getCategoriesOfUser(locationState.id);
    locationState?.id && getListPriceOfUser(locationState.id);
    getCollaboratorOptions();
    getCategoriesAndSubCategories();
  }, []);

  // Efecto para seleccionar todas las categorías y subcategorías si el perfil es "Vendedor"
  useEffect(() => {
    if (idProfile === 2 && categoriesOptions.length > 0) {
      const allCategories = categoriesOptions.map((cat) => cat.id);
      const allSubCategories = categoriesOptions.flatMap((cat) => cat.subcategories.map((subCat) => subCat.id));
      setIdsCategory(allCategories);
      setIdsSubCategory(allSubCategories);
    }
  }, [idProfile, categoriesOptions]);

  return (
    <>
      <LoadingIndicator loading={loading} />

      <h1>{title}</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField
            required
            id={"firstName"}
            label={"Nombre"}
            defaultValue={locationState?.firstName || ''}
            value={firstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(event.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            id={"lastName"}
            label={"Apellido"}
            value={lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(event.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            id={"email"}
            label={"Email"}
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id={"phone"}
            label={"Teléfono"}
            value={phone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPhone(event.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id={"password"}
            label={"Contraseña"}
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
            fullWidth
            type="password"
            autoComplete='new-password'
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id={"passwordConfirmation"}
            label={"Confirmar contraseña"}
            type="password"
            value={passwordConfirmation}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPasswordConfirmation(event.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <Select
              id={"idProfile"}
              label={"Perfil"}
              onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                setIdProfile(event.target.value as number);
              }}
              value={idProfile}
              fullWidth
            >
              {filteredProfileOptions.map((y: OptionType) => (
                <MenuItem
                  key={y.value}
                  style={y.color ? { color: y.color } : {}}
                  value={y.value}
                >
                  {y.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} hidden={idProfile >= 3}>
          <Select
            id={"color"}
            label={"Color"}
            onChange={(event) => {
              setColor(event.target.value as number);
            }}
            value={color}
            fullWidth
            hidden={idProfile !== 1}
          >
            {colorOptions.map((y: OptionType) => (
              <MenuItem
                key={y.value}
                style={y.color ? { color: y.color } : {}}
                value={y.value}
              >
                {y.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4} hidden={idProfile !== 2}>
          <Select
            id={"idCollaborator"}
            label={"Colaborador"}
            onChange={(event) => {
              setIdCollaborator(event.target.value as number);
            }}
            value={idCollaborator}
            fullWidth
            hidden={idProfile !== 2}
            disabled={isCollaborator}
          >
            {collaboratorOptions.map((y: OptionType) => (
              <MenuItem
                key={y.value}
                style={y.color ? { color: y.color } : {}}
                value={y.value}
              >
                {y.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4} hidden={idProfile >= 3}>
          <Select
            id={"idsCategory"}
            label={"Categoría"}
            multiple
            onChange={(event) => {
              setIdsCategory(event.target.value as number[]);
            }}
            value={idsCategory}
            fullWidth
          >
            {categoryOptionsMapper(categoriesOptions).map((y: OptionType) => (
              <MenuItem
                key={y.value}
                style={y.color ? { color: y.color } : {}}
                className={classes.root}
                value={y.value}
              >
                {y.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4} hidden={idProfile >= 3}>
          <Select
            id={"idsSubCategory"}
            label={"Sub-categoría"}
            multiple
            onChange={(event) => {
              setIdsSubCategory(event.target.value as number[]);
            }}
            value={idsSubCategory}
            fullWidth
          >
            {subCategoryOptionsMapper(categoriesOptions, idsCategory).map((y: OptionType) => (
              <MenuItem
                key={y.value}
                style={y.color ? { color: y.color } : {}}
                className={classes.root}
                value={y.value}
              >
                {y.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4} hidden={idProfile >= 3}>
          <Select
            id={"listPrice"}
            label={"Lista de Precio"}
            multiple
            onChange={(event) => {
              setListPrice(event.target.value as number[]);
            }}
            value={listPrice}
            fullWidth
          >
            {listPriceOptions.map((y: OptionType) => (
              <MenuItem
                key={y.value}
                style={y.color ? { color: y.color } : {}}
                className={classes.root}
                value={y.value}
              >
                {y.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button variant="contained" onClick={history.goBack}>Cancelar</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={async () => {
            let categories: any[] = [];
            for await (const category of idsCategory) {
              let subcats = await categoriesOptions.find(y => y.id === category)?.subcategories.map(z => z.id);
              for await (const subcategory of idsSubCategory.filter(x => subcats?.includes(x))) {
                categories.push({
                  idUser: locationState?.id || null,
                  idCategory: category,
                  idSubCategory: subcategory
                });
              }
            }
            let price: any[] = [];
            for await (const lp of listPrice) {
              price.push({
                listPrice: lp,
                idUser: locationState?.id || null
              });
            }
            const newValues = {
              firstName,
              lastName,
              email,
              phone,
              password,
              passwordConfirmation,
              idProfile,
              color,
              idCollaborator,
              idsCategory,
              idsSubCategory,
              isActive: true,
              isDeleted: false,
              categories,
              listPrice: price
            };
            if (mode === 'add') await handleAdd(newValues);
            else await handleEdit({ id: locationState.id, ...newValues });
            history.goBack();
          }}>
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UsersForm;
