import { Grid, Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link'

type Props = {
	baseUrl: string,
	onCancel?: () => void
}

const Actions = ({ baseUrl, onCancel }: Props) => {
	return(
		<Grid container direction="row" justify="flex-end" spacing={1}>
			<Grid item>
				{ onCancel
					? <Button variant="contained" onClick={onCancel}>Cancelar</Button>
					: <MaterialLink component={RouterLink} to={baseUrl}>
						<Button variant="contained">Cancelar</Button>
					</MaterialLink>
				}

			</Grid>
			<Grid item>
				<Button variant="contained" color="primary" type="submit">
					Guardar
				</Button>
			</Grid>
		</Grid>
	)
}

export default Actions
