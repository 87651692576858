import { GridCellParams } from '@material-ui/data-grid'
import { GridActions } from '../../../components'
import { IconButton } from '@material-ui/core'
import MenuOpen from '@material-ui/icons/MenuOpen'
import { RowBody } from '../../../components/grid/CustomGrid/types';


type Params = {
	onEdit: (x: GridCellParams) => void,
	onDelete: (x: GridCellParams) => void,
	onView: (x: GridCellParams) => void,
	rows: any
}


export function GetDataBody({ rows,
	onEdit,
	onDelete,
	onView
}: Params) {
	let dataBody: RowBody[] = [];

	const getAccions = (row: any) => {

		return (
			<>
				<IconButton aria-label="view" onClick={() => onView(row)}>
					<MenuOpen />
				</IconButton>
				<GridActions
					params={{ row }}
					handleEdit={() => onEdit(row)}
					handleDelete={onDelete}
				/>
			</>
		);
	}


	function GetRow(row: any): any[] {
		return [
			{
				cells: [
					{ key: 'id', field: row.id, tableCellProps: { component: "th", scope: "row", className: "stickySaeta" } },
					{ key: 'name', field: row.name },
					{ key: 'actions', field: getAccions(row) }
				],
				tableRowProps: {
					hover: true
				}
			}
		]
	}

	rows.map((row: any) => {
		row.id && GetRow(row).map((data: any) => dataBody.push(data));
	});
	return dataBody;
}

export const getDataHead = () => {
	let dataHeadTag = sessionStorage.getItem('dataHeadCategorias');
	if (dataHeadTag) {
		return JSON.parse(dataHeadTag)
	}
	return dataHead();
}

export const dataHead = () => [
	{ key: 'id', field: "id", tableCellProps: { align: "center", width: 40, className: "separador stickySaeta" } },
	{ key: 'name', field: "Nombre", tableCellProps: { align: "center", width: 250, className: "separador" } },
	{ key: 'actions', field: "Acciones", tableCellProps: { align: "center" } }
];

export const headerExport: any[] = [
	{ key: 'id', label: 'id' },
	{ key: 'name', label: 'Nombre' },
];

export function getDataExport(rows: any[]) {
	let dataBody: any[] = [];

	rows.map((row: any) => (
		dataBody.push(
		{
			id: row.id,
			name: row.name,
		}))
	);
	return dataBody;
}