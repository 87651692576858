import { OptionType } from '../../../types/form'

export const profileOptions: OptionType[] = [
	{ label: 'Colaborador', value: 1 },
	{ label: 'Vendedor', value: 2 },
	{ label: 'Repartidor', value: 3 },
	{ label: 'Usuario de depósito', value: 4 }
]

export const allProfileOptions: OptionType[] = [
	...profileOptions,
	{ label: 'Administrador', value: 5 }
]

export const collaboratorOptions: OptionType[] = [
	{ label: 'Colaborador 1', value: 1, color: 'red' },
	{ label: 'Colaborador 2', value: 1, color: 'lightgreen' },
	{ label: 'Colaborador 3', value: 1, color: 'lightblue' },
]

export const colorOptions: OptionType[] = [
	{ label: 'Verde', color: 'green', value: 1 },
	{ label: 'Naranja', color: 'orange', value: 2 },
	{ label: 'Rojo', color: 'red', value: 3 },
	{ label: 'Violeta', color: 'violet', value: 4 },
	{ label: 'Azul', color: 'blue', value: 5 },
	{ label: 'Rosa', color: 'pink', value: 6 },
	{ label: 'Amarillo', color: 'yellow', value: 7 },
	{ label: 'Blanco', color: 'white', value: 8 },
	{ label: 'Negro', color: 'black', value: 9 },
	{ label: 'Celeste', color: '#40CFFF', value: 10 },
	{ label: 'Marron', color: '#874a35', value: 11 },
	{ label: 'Rosa', color: '#f57875', value: 12 },
	{ label: 'Lila', color: '#B695C0', value: 13 },
	{ label: 'Gris', color: '#808080', value: 14 },
	{ label: 'ADM', color: '#76D7C4', value: 15 },
]

