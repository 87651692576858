import { useContext, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { Button, CircularProgress } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { VariantType, useSnackbar } from 'notistack';
import { AuthContext } from '../App';
import { authService } from '../services';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	buttonProgress: {
		color: 'green',
		position: 'absolute',
		marginTop: 32,
		marginLeft: -12,
		left: '50%',
	},
	logo: {
		width: '100%',
		marginTop: theme.spacing(5),
		outline: '2px solid',
		outlineColor: theme.palette.secondary.main
	},
	testData: {
		position: 'absolute',
		left: 50,
		bottom: 50,
		opacity: 0.5
	}
}))

const Login = () => {
	const classes = useStyles()

	const [email, setEmail] = useState('')
	const [userPassword, setUserPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const [buttonPressed, setButtonPressed] = useState(false)

	const { dispatch } = useContext(AuthContext)

	const { enqueueSnackbar } = useSnackbar();

	const showAlert = (variant: VariantType, message: string) => {
		enqueueSnackbar(message, { variant });
	}

	const getUserType = (idProfile: number) => {
		switch(idProfile) {
			case 1: return 'collaborator'
			case 2: return 'seller'
			case 3: return 'delivery'
			case 4: return 'deposit'
			case 5: return 'admin'
		}
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault()
		setLoading(true)
		setButtonPressed(true)
		try{
			const response = await authService.login(email, userPassword)
			const data = await response.json()
			const { token, user } = data
			dispatch({
				type: "LOGIN",
				payload: {
					token: token,
					id: user.id,
					user: user.email,
					type: getUserType(user.idProfile),
					idProfile: user.idProfile,
					fullName: `${user.firstName} ${user.lastName}`,
					categories: user.categories,
					listPrice: user.listPrice
				}
			})
		} catch(e) {
			showAlert('error', 'Email y/o contraseña incorrectos. Por favor intente de nuevo.')
		}
		setLoading(false)
	}

	const showError = buttonPressed && !loading
	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<form onSubmit={handleSubmit}>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Inicio de sesi&oacute;n
					</Typography>

					<div className={classes.form}>
						<TextField
							{ ...showError ? { error: true } : null }
							variant="outlined"
							margin="normal"
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="Email"
							autoFocus
							onChange={(e) => setEmail(e.target.value)}
						/>
						<TextField
							{ ...showError ? { error: true } : null }
							variant="outlined"
							margin="normal"
							fullWidth
							name="password"
							label="Contraseña"
							type="password"
							id="password"
							autoComplete="current-password"
							onChange={(e) => setUserPassword(e.target.value)}
						/>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={loading}
						>
							Ingresar
						</Button>
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}

						<img
							src={process.env.PUBLIC_URL + '/logo.jpeg' }
							alt="Tiendas Tic Logo"
							className={classes.logo}
						></img>


					</div>
				</div>
			</form>
		</Container>
	)
}

export default Login
