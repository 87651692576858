import { useContext } from 'react';
import { AuthContext } from '../../../App';
import { GridColDef } from '@material-ui/data-grid';
import { DefaultGrid } from '../../../components';
import { IconButton } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const getColumns = (selectedProducts: any, handleChange: any): GridColDef[] => [
  { field: 'name', headerName: 'Nombre', width: 250 },
  { field: 'description', headerName: 'Descripción', width: 150 },

  {
    field: 'idCategory',
    headerName: 'Categoría',
    width: 150,
    valueGetter: ({ row }) => row.category && row.category.name,
  },
  {
    field: 'idSubCategory',
    headerName: 'Sub-categoría',
    width: 130,
    valueGetter: ({ row }) => row.subCategory && row.subCategory.name,
  },
  { field: 'fullPrice', headerName: 'Precio' },
  { field: 'stock', headerName: 'Stock' },
  { field: 'gridStock', headerName: 'Stock Redes' },
  {
    field: 'actions',
    filterable: false,
    headerName: 'Cantidad seleccionada',
    flex: 1,
    renderCell: (params) => {
      const count = selectedProducts[params.row.id] || 0;
      return (
        <>
          <IconButton
            color="primary"
            onClick={() => handleChange('remove', params.row.id)}
            disabled={count === 0}
          >
            <RemoveCircleIcon />
          </IconButton>
          {count}
          <IconButton
            color="primary"
            onClick={() => handleChange('add', params.row.id)}
            disabled={count === params.row.stock}
          >
            <AddCircleIcon />
          </IconButton>
        </>
      );
    },
  },
];

const ProductsGrid = ({
  products,
  selectedProducts,
  setSelectedProducts,
}: any) => {
  const { userState } = useContext(AuthContext);
  const handleChange = (action: string, productId: number) => {
    const count = selectedProducts[productId] || 0;
    setSelectedProducts({
      ...selectedProducts,
      [productId]: count + (action === 'add' ? 1 : -1),
    });
  };

  return (
    <DefaultGrid
      rows={products.filter((p:any)=> p.stock>0 && userState.categories.find(uc => uc.idCategory == p.idCategory && uc.idSubCategory == p.idSubCategory))}
      columns={getColumns(selectedProducts, handleChange)}
      pageSize={5}
      height={500}
    />
  );
};

export default ProductsGrid;
