import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { SelectionDialog } from '../.';
import { SelectOptionType } from '../../types';

type Props = {
  icon?: React.ReactNode;
  tooltip?: string;
  handleSelection: (selectId: any) => void;
  message: string;
  options: SelectOptionType[];
  children?: any;
};

const SelectionAction = ({
  icon,
  tooltip,
  handleSelection,
  message,
  options,
  children,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const onConfirmation = (selectId: any) => {
    setDialogOpen(false);
    handleSelection(selectId);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, { onClick: () => setDialogOpen(true) })
      ) : (
        <Tooltip title={tooltip || ''}>
          <IconButton aria-label="view" onClick={() => setDialogOpen(true)}>
            {icon}
          </IconButton>
        </Tooltip>
      )}
      <SelectionDialog
        isOpen={dialogOpen}
        handleConfirmation={onConfirmation}
        handleRejection={() => setDialogOpen(false)}
        message={message}
        options={options}
        confirmMessage="Confirmar"
        cancelMessage="Volver"
        selectTitle="Repartidor"
      />
    </>
  );
};

export default SelectionAction;
