import baseService from './baseService';

const sellsService = {
  get: async () => baseService.httpGet('Sale'),
  getSalesClosed: async (params: any) => baseService.httpGet('Sale/GetSalesClosed', params),
  getSalesNotApprovedForToday: async (params: any) => baseService.httpGet('Sale/GetSalesNotApprovedForToday', params),
  find: async (id: any) => baseService.httpGet(`Sale/${id}`),
  add: async (item: any) => baseService.httpPost('Sale', item),
  edit: async (item: any) => baseService.httpPut('Sale', item),
  remove: async (idSale: any) => baseService.httpDelete('Sale', {}, { idSale }),
  assignDeliveryMan: async (nroVenta: number, deliverymen: number) =>
    baseService.httpPut('Sale/SetDeliveryMen', {}, { nroVenta, deliverymen }),
  changeStatus: async (nroVenta: number, state: number) =>
    baseService.httpPut('Sale/ChangeState', {}, { nroVenta, state }),
  changeDeliveryState: async (nroVenta: number, state: number, comment: string) =>
    baseService.httpPut('Sale/ChangeDeliveryState', {}, { nroVenta, state, comment }),
  addClient: async (client: any) =>
    {
      if(client.dni === '') delete(client.dni) 
      return baseService.httpPost('Sale/AddClient', client)},
  AddProductToCar: async (products: any) => baseService.httpPost('Sale/AddProductToCar', products, false),
  AddProductToCarWithList: async (products: any, listPrice: any) => baseService.httpPost(`Sale/AddProductToCar/${listPrice}`, products, false),
  getUpdateOldSales: async () => baseService.httpGet('Sale/UpdateOldSales'),
  getLocalitys: async (params: any) => baseService.httpGet('Sale/GetZones', params),
  getMessages: (type: string, response: any) => {
    switch (type) {
      case 'GET':
        return {
          error: 'Se produjo un error al intentar obtener las ventas',
        };
      case 'REMOVE':
        return {
          success: 'Venta eliminada correctamente',
          error: 'Se produjo un error al intentar eliminar la venta.',
        };
      case 'UPDATE':
        return {
          success: 'Venta actualizada correctamente',
          error: 'Se produjo un error al intentar actualizar la venta',
        };
      case 'ADD':
        return {
          success: 'Venta creada correctamente',
          error: 'Se produjo un error al intentar crear la venta',
        };
      default:
        return {
          error: 'Se produjo un error desconocido',
        };
    }
  },
};

export default sellsService;
