import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
	isOpen: boolean,
	handleRejection: () => void,
	handleConfirmation: () => void,
	confirmMessage: string,
	cancelMessage: string,
	message: string,
	description: string
}

const ConfirmationDialog = ({
	isOpen, handleRejection, handleConfirmation, confirmMessage, cancelMessage,
	message, description
}: Props) => {
	return (
		<Dialog
			open={isOpen}
			onClose={handleRejection}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{message}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{description}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleRejection} color="primary">
					{cancelMessage}
				</Button>
				<Button onClick={handleConfirmation} color="primary" autoFocus>
					{confirmMessage}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationDialog
