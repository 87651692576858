import { VariantType } from 'notistack';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../App';

// Service Function
type SF = (x?: any) => Promise<any>;

// Notification Function
type notificationF = (variant: VariantType, message: string) => void;

// Get Messages Function
type getMessagesF = (
  type: string,
  response: any
) => { error: string; success?: string };

const useCRUD = (
  serviceGet: SF | null,
  serviceAdd: SF,
  serviceEdit: SF,
  serviceRemove: SF,
  showNotification: notificationF,
  getMessages: getMessagesF,
  getId = (x: any) => x.id
) => {
  const [state, setState] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch } = useContext(AuthContext)

  const handleError = (e: Error) => {
    console.log(e);
    if(e.name==='TokenExpiredError')
      dispatch({ type: 'LOGOUT'});
    setLoading(false);
    //throw e;
  };

  const handleAdd = async (item: any) => {
    try {
      setLoading(true);
      const response = await serviceAdd(item);

      const id = Math.random();
      const newState = [...state, { ...item, id }];
      setState(newState);

      showNotification('success', getMessages('ADD', response).success || '');

      setLoading(false);
    } catch (e) {
      if(e.errors) {
      let errors = Object.entries(e.errors);
      errors.map((v:any)=>showNotification('error', v[0]+ " - " + v[1]));}
      showNotification('error', getMessages('ADD', e).error || '');
      handleError(e);
    }
  };

  const handleEdit = async (item: any) => {
    try {
      setLoading(true);
      const response = await serviceEdit(item);

      const newState = state.map((x) => (x.id === item.id ? item : x));
      setState(newState);

      showNotification(
        'success',
        getMessages('UPDATE', response).success || ''
      );

      setLoading(false);
    } catch (e) {
      if(e.errors) {
      let errors = Object.entries(e.errors);
      errors.map((v:any)=>showNotification('error', v[0]+ " - " + v[1]));}
      showNotification('error', getMessages('UPDATE', e).error || 'test');
      return handleError(e);
    }
  };

  const handleRemove = async (item: any) => {
    try {
      setLoading(true);

      const deleteId = item.row.id || item.row.nroVenta;
      const response = await serviceRemove(deleteId);

      const newState = state.filter((x) => getId(x) !== deleteId);
      setState(newState);

      showNotification(
        'success',
        getMessages('REMOVE', response).success || ''
      );

      setLoading(false);
    } catch (e) {
      if(e.errors) {
      let errors = Object.entries(e.errors);
      errors.map((v:any)=>showNotification('error', v[0]+ " - " + v[1]));}
      showNotification('error', getMessages('REMOVE', e).error);
      handleError(e);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      if (serviceGet !== null) {
        const data: any = await serviceGet();
        setState(data);
      }
      setLoading(false);
    } catch (e) {
      if(e.errors) {
      let errors = Object.entries(e.errors);
      errors.map((v:any)=>showNotification('error', v[0]+ " - " + v[1]));}
      showNotification('error', getMessages('GET', e).error);
      return handleError(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    handleAdd,
    handleEdit,
    handleRemove,
    loading,
    state,
    setLoading,
    setState,
  };
};

export default useCRUD;
