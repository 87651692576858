import React, { useContext } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MaterialLink from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom';
import List from '@material-ui/core/List'
import PeopleIcon from '@material-ui/icons/People'
import HomeIcon from '@material-ui/icons/Home'
import CategoryIcon from '@material-ui/icons/Category'
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import GavelIcon from '@material-ui/icons/Gavel';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { AuthContext } from './App'
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"

type LinkProps = {
	icon: React.ReactNode,
	text: string,
	route: string
}

const LinkItem = ({ icon, text, route }: LinkProps) => (
	<MaterialLink component={RouterLink} to={route}>
		<ListItem button>
			<ListItemIcon>
				{icon}
			</ListItemIcon>
			<ListItemText primary={text} />
		</ListItem>
	</MaterialLink>
)

const links: LinkProps[] = [
	{ icon: <HomeIcon />, text: 'Inicio', route: '/' },
	{ icon: <PeopleIcon />, text: 'Usuarios', route: '/usuarios' },
	{ icon: <CategoryIcon />, text: 'Categorías', route: '/categorias' },
	{ icon: <ListAltIcon />, text: 'Productos', route: '/productos' },
	{ icon: <ShoppingBasketIcon />, text: 'Ventas', route: '/ventas' },
	{ icon: <GavelIcon />, text: 'Reparaciones', route: '/reparaciones' },
	{ icon: <CalendarTodayIcon />, text: 'Listado Diario', route: '/listado-diario' },
	{ icon: <AttachMoneyIcon />, text: 'Comisiones', route: '/comisiones' },
]

const RouterLinkList = () => {

	const { userState } = useContext(AuthContext)
	//console.log('state 2', userState)
	const filteredLinks = links.filter(x => {
		if((userState.type !== 'admin' && userState.type !== 'collaborator') && x.route === '/usuarios') return false
		if(userState.type !== 'admin' && x.route === '/categorias') return false
		if((userState.type !== 'admin' && userState.type !== 'collaborator') && x.route === '/productos') return false
		if(userState.type !== 'deposit' && userState.type !== 'admin' && x.route === '/reparaciones') return false
		if((userState.type !== 'admin' && userState.type !== 'collaborator') && x.route === '/comisiones') return false
		if((userState.type === 'seller') && x.route === '/listado-diario') return false
		return true
	})

	return (
		<List>
			{filteredLinks.map((x, i) => <LinkItem key={i} {...x} />)}
		</List>
	)
}

export default RouterLinkList
