  import React, { useEffect, useState } from 'react';
  import { useParams, useHistory } from 'react-router-dom';
  import { sellsService } from '../../../services';
  import { Button } from '@material-ui/core';
  import './style.css';

  type Product = {
    id: string;
    name: string;
  };
  
  type ProductCar = {
    idProduct: string;
    quantity: number;
    price: number;
    cost: number;
    sellerComission: number;
    collaboratorComission: number;
    shippingUnitCost: number;
  };

  type Venta = {
    nroVenta: string;
    deliverydate: string;
    seller: {
      firstName: string;
      lastName: string;
    };
    client: {
      firstname: string;
      lastname: string;
      address: string;
      phone: string;
    };
    product: Product[];
    productCar: ProductCar[];
  };
  
  const RemitoPage = () => {
    const { nroVenta } = useParams<{ nroVenta?: string }>();
    const [venta, setVenta] = useState<Venta | null>(null);
    const history = useHistory();

    useEffect(() => {
      const fetchVenta = async () => {
        try {
          const venta = await sellsService.find(nroVenta); // Usar el método correcto
          console.log('Venta:', venta);
          setVenta(venta);
        } catch (error) {
          console.error('Error fetching venta:', error);
        }
      };
  
      fetchVenta();
    }, [nroVenta]);

    const handleCancel = () => {
      history.goBack();
    };
  
    const handlePrint = () => {
      window.print();
    };

    const renderProductos = () => {
    if (!venta || !venta.product || !venta.productCar) {
      return <p>No hay productos en esta venta.</p>;
    }

    return venta.productCar.map((productoCar) => {
      const productInfo = venta.product.find(p => p.id === productoCar.idProduct);

      return (
        <tr key={productoCar.idProduct}>
          <td>{productInfo ? productInfo.name : 'Producto desconocido'}</td>
          <td>{productoCar.quantity}</td>
          <td>{productoCar.price}</td>
        </tr>
      );
    });
  };
  
  return (
    <div className="remito-container">
      <div className="remito-header">
        <div className="remito-logo">
          <img src="logo.png" alt="Logo Tiendas TIC" />
          <h2>Tiendas TIC</h2>
        </div>
        <div className="remito-info">
          <h2>BERAZATEGUI</h2>
          <p><strong>Fecha:</strong> {venta ? venta.deliverydate : ''}</p>
          <p><strong>N° Pedido:</strong> {venta ? venta.nroVenta : ''}</p>
        </div>
      </div>
      <div className="remito-vendedor">
        <p><strong>Vendedor:</strong> {venta ? `${venta.seller.firstName} ${venta.seller.lastName}` : ''}</p>
      </div>
      <div className="remito-cliente">
        <p><strong>Nombre:</strong> {venta ? `${venta.client.firstname} ${venta.client.lastname}` : ''}</p>
        <p><strong>Dirección:</strong> {venta ? venta.client.address : ''}</p>
        <p><strong>Teléfono:</strong> {venta ? venta.client.phone : ''}</p>
      </div>
      <table className="remito-productos">
        <thead>
          <tr>
            <th>Artículo</th>
            <th>Cant</th>
            <th>Precio total</th>
          </tr>
        </thead>
        <tbody>
          {renderProductos()}
        </tbody>
      </table>
      <div className="remito-footer">
        <Button variant="contained" color="secondary" onClick={handleCancel} style={{ marginRight: '10px' }}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handlePrint}>
          Imprimir
        </Button>
      </div>
    </div>
  );
};
  
  export default RemitoPage;
  