import GoogleMapReact from 'google-map-react';
import { useHistory } from 'react-router-dom';
import { MapMarker, SelectionAction } from '../../../components';
import isToday from 'date-fns/isToday';
import { Button, Dialog, Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../App';
import config from '../../../config';

const mapCenter = {
  lat: -34.664796,
  lng: -58.532447,
};

const key = String(config.GoogleKey);

type Props = {
  data: any[];
  delivery: any;
};

function parseLocation(sell: any) {
  try {
    return { ...sell, location: JSON.parse(sell.location) };
  } catch (e) {
    return sell;
  }
}

function filterLocationObject(sell: any) {
  return Boolean(sell.location) && typeof sell.location === 'object';
}

function filterCurrentSales(sell: any) {
  return isToday(new Date(sell.deliverydate));
}

function filterRepartidorAsignado(sell: any) {
  return !Boolean(sell.deliveryMan.id);
}

const Map = ({ data, delivery }: Props) => {
  const history = useHistory();
  const locations = data
    .map(parseLocation)
    .filter(filterLocationObject)
    .filter(filterCurrentSales)
    .filter(filterRepartidorAsignado);

  // console.log({ locations, data });
  const [nroVenta, setNroVenta] = useState('');
  const [open, setOpen] = useState(false);
  const { userState } = useContext(AuthContext);

  return (
    <>
    <Dialog onClose={() => {setOpen(false);setNroVenta('')}} aria-labelledby="simple-dialog-title" open={open}>
      <SelectionAction
        children={
          <Button>
            Asignar Repartidor
          </Button>
        }
        tooltip="Asignar repartidor"
        handleSelection={(deliveryManId)=>delivery.action(nroVenta, deliveryManId)}
        message="Seleccione un repartidor"
        options={delivery.options}
      />
      <Button onClick={()=>history.push(`ventas/${nroVenta}/detalle`)}>Ver Detalle</Button>
    </Dialog>
    <GoogleMapReact
      bootstrapURLKeys={{ key }}
      defaultZoom={12}
      defaultCenter={mapCenter}
    >
      {locations.map((x) => (
        <MapMarker
          key={x.nroVenta}
          title={
            <React.Fragment>
            <Typography color="inherit">Venta número {x.nroVenta}</Typography>
            {x.product.map((p: any) => (
              <Typography key={p.id} color="inherit">{x.productCar.find((y: any) => y.idProduct === parseInt(p.id)).quantity}. - {p.name}</Typography>
            ))}
            </React.Fragment>}
          lat={x.location.latitude}
          lng={x.location.longitude}
          onClick={() => {if(userState.type === 'collaborator') {history.push(`ventas/${nroVenta}/detalle`)} else {setOpen(true);setNroVenta(x.nroVenta);}}}
          classColor={
            x.client.deliveryhour==="9 a 13hs" || x.client.deliveryhour==="9-13" ? "bg-morning" : 
            x.client.deliveryhour==="13 a 18hs" || x.client.deliveryhour==="13-18" ? "bg-after" : 
            "bg-free"
          }
        />
      ))}
    </GoogleMapReact>
    </>
  );
};

export default Map;
