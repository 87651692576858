import React, { useContext } from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import { Drawer, FormControlLabel, Switch } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { AuthContext } from '../App'
import { ConfirmationDialog } from '.'
const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: 36,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9) + 1,
			},
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		themeSelector: {
			marginLeft: 'auto'
		}
	})
)

type Props = {
	children: React.ReactNode,
	handleToggleThemeMode: (x: any) => void,
	routerLinkList: React.ReactNode
}

const BaseLayout = ({ children, handleToggleThemeMode, routerLinkList }: Props) => {
	const classes = useStyles()
	const theme = useTheme()
	const [open, setOpen] = React.useState(false)
	const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false)
	const { dispatch } = useContext(AuthContext)

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	return (
		<>
		<div className={classes.root} style={{"maxWidth": "100vw"}}>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
						[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap>
						Logo Tiendas TIC
					</Typography>
					<div className={classes.themeSelector}>
						<FormControlLabel
							control={<Switch onClick={handleToggleThemeMode} />}
							label=""
						/>
					</div>
					<div>
						<IconButton
							onClick={() => {setLogoutDialogOpen(true)}}
							color="secondary"
						>
							<ExitToAppIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>

			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</div>
				<Divider />
				{routerLinkList}
			</Drawer>
			<main className={classes.content} style={{"maxWidth": "95vw"}}>
				<div className={classes.toolbar} />
				{children}
			</main>
		</div>

		<ConfirmationDialog
			isOpen={logoutDialogOpen}
			handleRejection={() => setLogoutDialogOpen(false)}
			handleConfirmation={() => dispatch({ type: 'LOGOUT'})}
			confirmMessage="Cerrar sesión"
			cancelMessage="Volver"
			message="¿Estás seguro de que deseas cerrar sesión?"
			description=""
		/>

		</>
	)
}

export default BaseLayout
