import { useState } from 'react';
import { ConfirmationDialog } from '../../../components';
import { IconButton, Tooltip } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type Props = {
  params: any;
  onReparation: (x: GridCellParams) => void;
};

const ReparationAction = ({ params, onReparation }: Props) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  return (
    <>
      <Tooltip title="Confirmar reparación">
        <IconButton
          aria-label="repair"
          onClick={() => setConfirmationOpen(true)}
        >
          <CheckCircleIcon color="primary" />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        isOpen={confirmationOpen}
        handleConfirmation={() => {
          setConfirmationOpen(false);
          onReparation(params);
        }}
        handleRejection={() => setConfirmationOpen(false)}
        message={`Estás seguro de que deseas confirmar la reparación N° ${params.nrorepair}?`}
        description="El stock se actualizará automáticamente"
        confirmMessage="Confirmar"
        cancelMessage="Volver"
      />
    </>
  );
};

export default ReparationAction;
