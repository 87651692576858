import { useState } from 'react';
import { ConfirmationDialog } from '../../../components';
import { IconButton, Tooltip } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SELL_STATUSES } from '../form/options';
import CancelIcon from '@material-ui/icons/Cancel';

type Props = {
  params: any;
  onCancel: (x: GridCellParams) => void;
  onApprove: (x: GridCellParams) => void;
};

const ApproveAndCancelActions = ({ params, onCancel, onApprove }: Props) => {
  const [approveConfirmationOpen, setApproveConfirmationOpen] = useState(false);
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);

  return (
    <>
      {SELL_STATUSES.pending === params.row.salestate && (
        <Tooltip title="Aprobar venta">
          <IconButton
            aria-label="approve"
            onClick={() => setApproveConfirmationOpen(true)}
          >
            <CheckCircleIcon color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {(2 !== params.row.deliverystate) && (3 !== params.row.deliverystate) && (
      <Tooltip title="Cancelar venta">
        <IconButton
          aria-label="cancel"
          onClick={() => setCancelConfirmationOpen(true)}
        >
          <CancelIcon color="error" />
        </IconButton>
      </Tooltip>
      )}
      <ConfirmationDialog
        isOpen={approveConfirmationOpen}
        handleConfirmation={() => {
          setApproveConfirmationOpen(false);
          onApprove(params);
        }}
        handleRejection={() => setApproveConfirmationOpen(false)}
        message={`Estás seguro que deseas aprobar la venta N° ${params.row.nroVenta}?`}
        description=""
        confirmMessage="Aprobar venta"
        cancelMessage="Volver"
      />
      <ConfirmationDialog
        isOpen={cancelConfirmationOpen}
        handleConfirmation={() => {
          setCancelConfirmationOpen(false);
          onCancel(params);
        }}
        handleRejection={() => setCancelConfirmationOpen(false)}
        message={`Estás seguro que deseas cancelar la venta N° ${params.row.nroVenta}?`}
        description="El stock se actualizará automáticamente"
        confirmMessage="Cancelar venta"
        cancelMessage="Volver"
      />
    </>
  );
};

export default ApproveAndCancelActions;
