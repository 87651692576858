import { GridCellParams } from '@material-ui/data-grid';
import { useHistory, useLocation } from 'react-router-dom';
import { GridHeader, LoadingIndicator } from '../../../components'
import { useCRUD, useNotifications } from '../../../hooks';
import { subCategoriesService } from '../../../services';
import {getDataHead, GetDataBody, headerExport, getDataExport} from './columnsGetter';
import { CustomGridTable } from "../../../components/grid/CustomGrid";
import { changeViewColumn } from '../../../components/grid/CustomGrid/Funtions';
import { useState } from 'react';

const SubCategoriesGrid = () => {

	const history = useHistory()
	const { showNotification } = useNotifications()
	const { state: locationState }: any = useLocation()
	const categoryId = locationState.category?.id
	const categoryName = locationState.category?.name

	const { handleRemove, loading, state: rows } = useCRUD(
		async() => subCategoriesService.getByCategoryId(categoryId),
		subCategoriesService.add,
		subCategoriesService.edit,
		subCategoriesService.remove,
		showNotification,
		subCategoriesService.getMessages
	)

	const onEdit = (params: GridCellParams) => {
		history.push('/sub-categorias/editar', { ...params.row, ...locationState }
	)}

	const onAdd = () => {
		history.push('/sub-categorias/agregar', { ...locationState })
	}
  
	const querystring = window.location.search
	const params = new URLSearchParams(querystring)

    const [dataHeadColumns, setDataHeadColumns] = useState(getDataHead());
  
    const handleChangeViewColumn = (key: string) => {
      changeViewColumn(key, "dataHeadSubCategorias", dataHeadColumns, setDataHeadColumns)
    }
    
    const [page, setPage] = useState(parseInt(params.get('page') || "0"));
    const changePage = (newPage: number) => {
      history.push(`?page=${newPage}`)
      setPage(newPage);
    }

	return <>
		<LoadingIndicator loading={loading} />
		<GridHeader
			addUrl="sub-categorias/agregar"
			onAdd={onAdd}
			title={`Sub-categorías de la categoría ${categoryName}`}
			buttonText="Nueva sub-categoría"
			backUrl="/categorias"
		/>
		<CustomGridTable
			cellsHead={dataHeadColumns} 
			rowsBody={GetDataBody({rows,
				onEdit,
				onDelete: handleRemove,
			})} 
			pageSize={10} 
			pageParent={page}
			changePage={changePage}
			tableProps={{stickyHeader: true, size:"small"}}
			rowsPerPageOptions={[5, 10, 25]}
			toolbarProps={{
				exportProps: {
				data:getDataExport(rows), headers:headerExport, filename:"subcategorias.csv", name:"subcategorias"
				},
				filterProps: {
				columns: dataHeadColumns,
				handleChangeViewColumn
				}
			}}
			orderDefault={"asc"}
			orderByDefault={dataHeadColumns[0].key}
			idStoreFilter="filtrosSubCategorías"
		/>
	</>
}

export default SubCategoriesGrid
