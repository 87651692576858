import {
	TextField, Grid, Select, MenuItem, InputLabel, FormControl, FormHelperText
} from '@material-ui/core'
import MapAutocomplete from './MapAutocomplete';
import { OptionType } from '../../types/form'

type Props = {
	fields: any
}

const Fields = ({ fields }: Props) => {
	return(
		<Grid container spacing={3}>
			{fields.map((x: any) => (
				<Grid key={x.id} item xs={12} md={4}>
					{ x.type === 'select'
						? <>
							<FormControl error={x.error} fullWidth>
								<InputLabel id={x.id}>{x.label}</InputLabel>
								<Select {...x}>
									{x.options.map((y: OptionType) => (
										<MenuItem
											style={y.color ? { color: y.color } : {}}
											value={y.value}
										>
											{y.label}
										</MenuItem>
									))}
								</Select>
								<FormHelperText>{x.helperText}</FormHelperText>
							</FormControl>
						</>
						:
						x.type==='mapAutocomplete' ? <>
							<MapAutocomplete {...x} defaultInputValue={x.value || ''} />
						</> 
						: <TextField {...x} fullWidth />
					}
				</Grid>
			))}
		</Grid>
	)
}

export default Fields
