import { Grid, Button } from '@material-ui/core'
import MaterialLink from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

type Props = {
	addUrl?: string,
	title: string,
	buttonText?: string,
	button2Text?: string,
	button2Action?: string,
	backUrl?: string,
	onAdd?: () => void,
	children?: JSX.Element,
}

const GridHeader = ({ addUrl, title, buttonText, backUrl, onAdd, children, button2Text, button2Action }: Props) => {
	return(
		<Grid
			container
			direction="row"
			justify="space-between"
			alignItems="center"
		>
			<h1>
				{backUrl
					&& <MaterialLink component={RouterLink} to={backUrl}>
							<ArrowBackIosIcon
								style={{ fontSize: 40, marginBottom: -8, marginRight: 10 }}
							/>
						</MaterialLink>
				}
				{title}
			</h1>

			{children || null}

			{button2Text && button2Action && 
				<MaterialLink component={RouterLink} to={button2Action||''}>
					<Button variant="contained" color="primary">
						{button2Text}
					</Button>
				</MaterialLink>
			}
			{buttonText &&
				( onAdd
					? <Button variant="contained" color="primary" onClick={onAdd}>
							{buttonText}
						</Button>
					: <MaterialLink component={RouterLink} to={addUrl||''}>
						<Button variant="contained" color="primary">
							{buttonText}
						</Button>
					</MaterialLink>
				)
			}
			
			{!buttonText ? <div></div> : null}
		</Grid>
	)
}

export default GridHeader
