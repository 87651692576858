import baseService from './baseService'

const productsService = {
	get: async() => baseService.httpGet('Product'),
	add: async(item: any) => baseService.httpPost('Product', item),
	edit: async(item: any) => baseService.httpPut('Product', item),
	remove: async(idProduct: any) => baseService.httpDelete('Product', {}, { idProduct }),
	getMessages: (type: string, response: any) => {
		switch(type){
			case 'GET': return {
				error: 'Se produjo un error al intentar obtener los productos'
			}
			case 'REMOVE': return {
				success: 'Producto eliminado correctamente',
				error: 'Se produjo un error al intentar eliminar el producto. '
					+ 'Verifique que no posea ventas asociadas.'
			}
			case 'UPDATE': return {
				success: 'Producto actualizado correctamente',
				error: 'Se produjo un error al intentar actualizar el producto'
			}
			case 'ADD': return {
				success: 'Producto creado correctamente',
				error: 'Se produjo un error al intentar crear el producto'
			}
			default: return {
				error: 'Se produjo un error desconocido'
			}
		}
	}
}

export default productsService
