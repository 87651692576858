import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { SelectOptionType } from '../types';

type Props = {
  isOpen: boolean;
  handleRejection: () => void;
  handleConfirmation: (x: any) => void;
  confirmMessage: string;
  cancelMessage: string;
  message: string;
  selectTitle: string;
  options: SelectOptionType[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(3),
      minWidth: 200,
    },
  })
);

const SelectionDialog = ({
  isOpen,
  handleRejection,
  handleConfirmation,
  confirmMessage,
  cancelMessage,
  message,
  selectTitle,
  options,
}: Props) => {
  const classes = useStyles();
  const [selection, setSelection] = useState(null);

  const handleSelectionChange = (e: React.ChangeEvent<{ value: any }>) => {
    setSelection(e.target.value);
  };

  useEffect(() => {
    setSelection(null);
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleRejection}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">{selectTitle}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selection}
            onChange={handleSelectionChange}
          >
            {options.map((x) => (
              <MenuItem value={x.value}>{x.description}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRejection} color="primary">
          {cancelMessage}
        </Button>
        <Button
          onClick={() => handleConfirmation(selection)}
          color="primary"
          disabled={selection === null}
          autoFocus
        >
          {confirmMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectionDialog;
