import { useFormik } from "formik";
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingIndicator, FormActions, FormFields } from '../../../components'
import { fieldBuilder } from '../../../utils'
import { validationSchema } from './validationSchema'
import { useCRUD, useNotifications } from '../../../hooks';
import { subCategoriesService } from '../../../services';

const SubCategoriesForm = ({ mode }: { mode: string }) => {
	const title = `${mode === 'add' ? 'Agregar' : 'Editar'} sub-categoría`
	const history = useHistory()
	const { state: locationState }: any = useLocation()
	const categoryId = locationState?.category?.id || null
	const { showNotification } = useNotifications()

	const { handleAdd, handleEdit, loading } = useCRUD(
		null,
		subCategoriesService.add,
		subCategoriesService.edit,
		subCategoriesService.remove,
		showNotification,
		subCategoriesService.getMessages
	)

	const formik = useFormik({
		initialValues: {
			name: locationState?.name || '',
			description: locationState?.description || ''
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			if(mode === 'add')
				await handleAdd({ 
					...values,
					idCategory: categoryId
				})
			else
				await handleEdit({
					id: locationState.id,
					idCategory: categoryId,
					...values,
				})
			history.goBack()
		},
	})

	const fields = [
		{ id: 'name', label: 'Nombre' },
	].map(x => fieldBuilder(x, formik)).filter(x => !x.hidden)

	const onCancel = () => {
		history.goBack()
	}

	return <>
		<LoadingIndicator loading={loading} />

		<h1>{title}</h1>

		<form onSubmit={formik.handleSubmit}>
			<FormFields fields={fields} />
			<FormActions baseUrl="/sub-categorias" onCancel={onCancel} />
		</form>
	</>
}

export default SubCategoriesForm
