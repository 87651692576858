import { GridCellParams } from '@material-ui/data-grid';
import { GridHeader, LoadingIndicator } from '../../../components'
import { useHistory } from 'react-router-dom';
import { useCRUD, useNotifications } from '../../../hooks';
import { categoriesService } from '../../../services';
import {getDataHead, GetDataBody, headerExport, getDataExport} from './columnsGetter';
import { CustomGridTable } from "../../../components/grid/CustomGrid";
import { changeViewColumn } from '../../../components/grid/CustomGrid/Funtions';
import { useState } from 'react';

const CategoriesGrid = () => {
  
	const querystring = window.location.search
	const params = new URLSearchParams(querystring)

	const history = useHistory()
	const { showNotification } = useNotifications()

	const { handleRemove, loading, state: rows } = useCRUD(
		categoriesService.get,
		categoriesService.add,
		categoriesService.edit,
		categoriesService.remove,
		showNotification,
		categoriesService.getMessages
	)

	const onEdit = (params: GridCellParams) => {
		history.push('/categorias/editar', { ...params })
	}

	const onView = (params: GridCellParams) => {
		history.push('/sub-categorias', { category: {...params} })
	}

    const [dataHeadColumns, setDataHeadColumns] = useState(getDataHead());
  
    const handleChangeViewColumn = (key: string) => {
      changeViewColumn(key, "dataHeadCategorias", dataHeadColumns, setDataHeadColumns)
    }
    
    const [page, setPage] = useState(parseInt(params.get('page') || "0"));
    const changePage = (newPage: number) => {
      history.push(`?page=${newPage}`)
      setPage(newPage);
    }

	return(
		<>
			<LoadingIndicator loading={loading} />
			<GridHeader
				addUrl="categorias/agregar"
				title="Categorías"
				buttonText="Nueva categoría"
			/>
			<CustomGridTable
				cellsHead={dataHeadColumns} 
				rowsBody={GetDataBody({rows,
					onEdit,
					onDelete: handleRemove,
					onView
				})} 
				pageSize={10} 
				pageParent={page}
				changePage={changePage}
				tableProps={{stickyHeader: true, size:"small"}}
				rowsPerPageOptions={[5, 10, 25]}
				toolbarProps={{
					exportProps: {
					data:getDataExport(rows), headers:headerExport, filename:"categorias.csv", name:"categorias"
					},
					filterProps: {
					columns: dataHeadColumns,
					handleChangeViewColumn
					}
				}}
				orderDefault={"asc"}
				orderByDefault={dataHeadColumns[0].key}
				idStoreFilter="filtrosCategorías"
				/>
		</>
	)
}

export default CategoriesGrid
