import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import throttle from 'lodash/throttle';
import config from '../../config';
var parse = require('autosuggest-highlight/parse');

const autocompleteService = { current: null };

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

const key = String(config.GoogleKey);

type Props = {
    handleLocation: Function;
    id: string;
    label: string;
    setValueAddress: Function;
    defaultInputValue: string;
};

export default function GoogleMaps({handleLocation, id, label, setValueAddress, defaultInputValue}: Props) {
    
	const {
		placesService,
		placePredictions,
		getPlacePredictions,
	  } = usePlacesService({
		apiKey: key,
        options: {
          types: ["address"],
          componentRestrictions: { country: "arg" },
        }

	});
  const classes = useStyles();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState(defaultInputValue || '');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  
  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);


    React.useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (placePredictions.length)
        placesService?.getDetails(
            {
            placeId: placePredictions[0].place_id,
            },
            (placeDetails: any) => {
                handleLocation({lat: placeDetails.geometry.location.lat(),lng: placeDetails.geometry.location.lng()})
            }
        );
    }, [placePredictions]);
  return (
    <Autocomplete
      id={id}
      fullWidth
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        getPlacePredictions({ input: newValue?.description });
        setValueAddress(newValue?.description);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {let inp:any = params.inputProps;let val = inp.value;return(
        <TextField {...params} label={label} fullWidth inputProps={{
          ...params.inputProps,
          value: val || defaultInputValue
        }} />
      )}}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part:any, index:any) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}