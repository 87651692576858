import "./MapMarker.css";
import { Tooltip } from "@material-ui/core";

const Marker = ({ title, classColor, ...rest }: any) => (
  <div {...rest}>
    <Tooltip title={title}>
      <div className={`pin bounce ${classColor}`} style={{ cursor: "pointer" }} />
    </Tooltip>
    <div className="pulse" />
  </div>
);

export default Marker;
