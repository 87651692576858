import config from '../config';
import { verify } from 'jsonwebtoken';

const validate = (response: any) => {
  if (response.status >= 400) {
    throw response;
  }

  return response;
};

const getUserIdProfile = () => {
  const storage: any = localStorage.getItem('userState');
  const userState = JSON.parse(storage);
  return userState?.idProfile || null;
};

const getBody = (body: any) => {
  const newBody = {
    ...body,
    profileUserLogin: getUserIdProfile(),
  };
  return JSON.stringify(newBody);
};

export const getTokenHeader = () => {
  const storage: any = localStorage.getItem('userState');
  const userState = JSON.parse(storage);
  const token = userState?.token || null;
  try {
    verify(token,config.key);
    return { Authorization: 'Bearer ' + token };
  } catch (error) {
    throw error;
  }
};

const buildUrl = (url: string, params: any = {}) => {
  const queries = Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    )
    .join('&');

  return queries.length
    ? `${config.apiUrl}${url}?${queries}`
    : `${config.apiUrl}${url}`;
};

const baseService = {
  httpLogin: async (url: string, body = {}, withProfileUser = true) => {
    const response = await fetch(buildUrl(url), {
      method: 'POST',
      body: withProfileUser ? getBody(body) : JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        accept: ' */*',
      },
    });

    return validate(response);
  },
  httpGet: async (url: string, params = {}) => {
    try {
      let tokenHeader = getTokenHeader();
      const response = await fetch(buildUrl(url, params), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...tokenHeader,
        },
      });
  
      const responseJson = await validate(response).json();
      return responseJson;
    } catch (error) {
      throw error;
    }

  },
  httpPost: async (url: string, body = {}, withProfileUser = true) => {
    try {
      let tokenHeader = getTokenHeader();
      const response = await fetch(buildUrl(url), {
        method: 'POST',
        body: withProfileUser ? getBody(body) : JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          accept: ' */*',
          ...tokenHeader,
        },
      });
  
      return validate(response);
    } catch (error) {
      throw error;
    }
  },
  httpPut: async (url: string, body = {}, params = {}) => {
    try {
      let tokenHeader = getTokenHeader();
      const response = await fetch(buildUrl(url, params), {
        method: 'PUT',
        body: getBody(body),
        headers: {
          'Content-Type': 'application/json',
          accept: ' */*',
          ...tokenHeader,
        },
      });
  
      return validate(response);
    } catch (error) {
      throw error;
    }
  },
  httpDelete: async (url: string, body = {}, params = {}) => {
    try {
      let tokenHeader = getTokenHeader();
      const response = await fetch(buildUrl(url, params), {
        method: 'DELETE',
        body: getBody(body),
        headers: {
          'Content-Type': 'application/json',
          accept: ' */*',
          ...tokenHeader,
        },
      });
  
      return validate(response);
    } catch (error) {
      throw error;
    }
  },
};

export default baseService;
