import baseService from './baseService';

const usersService = {
  get: async () => baseService.httpGet('User'),
  add: async (item: any) => baseService.httpPost('User', item),
  edit: async (item: any) => baseService.httpPut('User', item),
  remove: async (idUser: any) => baseService.httpDelete('User', {}, { idUser }),
  getCollaborators: async () => baseService.httpGet('User/collaborators'),
  getDeliverymans: async () => baseService.httpGet('User/deliverysman'),
  getSellersOfCollaborator: async (idCollaborator: any) => baseService.httpGet(`User/GetSellersOfCollaborator`, { idCollaborator }),
  getCategoriesOfUser: async (idUser: any) => baseService.httpGet(`User/CategoriesOfUser/${idUser}`),
  getListPriceOfUser: async (idUser: any) => baseService.httpGet(`User/ListPriceOfUser/${idUser}`),
  deactivateUser: async (id: any, newStatus: Boolean) => baseService.httpPut(`User/activatedesactivate/${id}/${newStatus}`),
  getMessages: (type: string, response: any) => {
    switch (type) {
      case 'GET':
        return {
          error: 'Se produjo un error al intentar obtener los usuarios',
        };
      case 'REMOVE':
        return {
          success: 'Usuario eliminado correctamente',
          error:
            'Se produjo un error al intentar eliminar el usuario. ' +
            'Verifique que no posea ventas asociadas.',
        };
      case 'UPDATE':
        return {
          success: 'Usuario actualizado correctamente',
          error: 'Se produjo un error al intentar actualizar el usuario',
        };
      case 'ADD':
        return {
          success: 'Usuario creado correctamente',
          error: 'Se produjo un error al intentar crear el usuario',
        };
      case 'GET_COLLABORATORS':
        return {
          error: 'Se produjo un error al intentar obtener los colaboradores',
        };
      case 'GET_CATEGORIES_OF_USER':
        return {
          error: 'Se produjo un error al intentar obtener las categorias del usuario',
        };
      case 'GET_LISTPRICE_OF_USER':
        return {
          error: 'Se produjo un error al intentar obtener las listas de precios del usuario',
        };
      default:
        return {
          error: 'Se produjo un error desconocido',
        };
    }
  },
};

export default usersService;
